import { HttpClient } from '@angular/common/http';
import { IPage, Page } from '../models/page.model';
import { Injectable } from '@angular/core';
import { Observable, observable, merge, combineLatest, forkJoin } from 'rxjs';
import { map, concatAll } from 'rxjs/operators';
import {
  IRole,
  Role,
  ITenantType,
  TenantSection,
  TenantType,
  IContentCategory,
  ContentCategory,
  IPushNotificationMessage,
  PushNotificationMessage,
  IMediaContent,
  MediaContent
} from '@koomzo/commonmodel';
import {
  DataDictionaryItem,
  IDataDictionary,
  DataDictionary,
  ITag,
  Tag,
  LocaleProp,
  ICountry,
  Country,
  ICurrency,
  Currency,
  ILanguage,
  Language,
  ICountrySettings,
  CountrySettings
} from '@koomzo/coremodel';
import { isObject } from '@koomzo/commonutil';

/**
 * service to load config data(lookup and sample data). required by the application.
 * This include common(for all koomzo apps) lookup data in config path and app specific lookup data
 * in app/config path
 */

@Injectable({ providedIn: 'root' })
export class JsonConfigService {
  readonly setupCommonDataPath = 'assets/config/setup-data.json';
  readonly setupBusinessDataPath = 'assets/app/config/app-setup-data.json';
  readonly setupAppConfigDataPath = 'assets/app/config';
  constructor(private http: HttpClient) {}

  getPageConfig(isflattened?: boolean): Observable<IPage[]> {
    let commonPages$ = this.getPageConfigHelper('assets/config/pages-config.json', isflattened);
    let appPages$ = this.getPageConfigHelper('assets/app/config/app-pages-config.json', isflattened);
    return merge(commonPages$, appPages$);
  }

  async getPageConfig1(isflattened?: boolean): Promise<IPage[]> {
    let commonPages = await this.getPageConfigHelper('assets/config/pages-config.json', isflattened).toPromise();
    let appPages = await this.getPageConfigHelper('assets/app/config/app-pages-config.json', isflattened).toPromise();
    const setupPages = [...commonPages, ...appPages];
    return setupPages;
  }

  async getDataDictionary(): Promise<IDataDictionary[]> {
    const commonData = await this.getDataDictionaryHelper(this.setupCommonDataPath).toPromise();
    const businessData = await this.getDataDictionaryHelper(this.setupBusinessDataPath).toPromise();
    const setupData = [...commonData, ...businessData];
    return setupData;
  }

  async getTenantType(): Promise<ITenantType[]> {
    const commonData = await this.getTenantTypeHelper(this.setupCommonDataPath).toPromise();
    const businessData = await this.getTenantTypeHelper(this.setupBusinessDataPath).toPromise();
    const setupData = [...commonData, ...businessData];
    return setupData;
  }

  async getRolesAndPermissionsData(): Promise<IRole[]> {
    const commonData = await this.getRolesAndPermissionsDataHelper(this.setupCommonDataPath).toPromise();
    const businessData = await this.getRolesAndPermissionsDataHelper(this.setupBusinessDataPath).toPromise();
    const setupData = [...commonData, ...businessData];
    return setupData;
  }

  async getCountryList(): Promise<ICountry[]> {
    const setupData = await this.getCountryHelper(`${this.setupAppConfigDataPath}/country.json`).toPromise();
    return setupData;
  }

  async getLanguageList(): Promise<ILanguage[]> {
    const setupData = await this.getLanguageHelper(`${this.setupAppConfigDataPath}/languages.json`).toPromise();
    return setupData;
  }

  async getCurrencyList(): Promise<ICurrency[]> {
    const setupData = await this.getCurrencyHelper(`${this.setupAppConfigDataPath}/currency.json`).toPromise();
    return setupData;
  }

  async getCountrySettingsList(): Promise<ICountrySettings[]> {
    const setupData = await this.getCountrySettingsHelper(`${this.setupAppConfigDataPath}/country-settings.json`).toPromise();
    return setupData;
  }

  async getSeedTags(): Promise<ITag[]> {
    const setupData = await this.getSeedTagsHelper(this.setupBusinessDataPath).toPromise();
    return setupData;
  }

  async getSeedContentCategory(): Promise<IContentCategory[]> {
    const setupData = await this.getSeedContentCategoryHelper(this.setupBusinessDataPath).toPromise();
    return setupData;
  }

  async getSeedMediaContent(): Promise<IMediaContent[]> {
    const setupData = await this.getSeedMediaContentHelper(this.setupBusinessDataPath).toPromise();
    return setupData;
  }

  async getSeedPushNotification(): Promise<IPushNotificationMessage[]> {
    const setupData = await this.getSeedPushNotificationHelper(this.setupBusinessDataPath).toPromise();
    return setupData;
  }

  private getPageConfigHelper(filePath: string, isflattened?: boolean): Observable<IPage[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        if (!Array.isArray(data) || data.length === 0) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        let pages: IPage[] = [];
        data.forEach(item => {
          pages.push(new Page({ ...item }));
        });

        if (isflattened === true) {
          let flattenedPages = [];
          pages.forEach(p => {
            if (p.children && p.children.length > 0) {
              p.children.forEach(cp => {
                flattenedPages.push(cp);
              });
            } else {
              flattenedPages.push(p);
            }
          });
          pages = flattenedPages;
        }
        return pages;
      }) // transform to ProjectModel
    );
  }

  private getDataDictionaryHelper(filePath: string): Observable<IDataDictionary[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['dataDictionary'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const dataDictionary: IDataDictionary[] = [];

        const dataTypeSet = new Set(pData.map(d => d.dataType));
        dataTypeSet.forEach(dt => {
          let dtObj = pData.filter(pd => pd.dataType === dt);
          let dataTypeItems = dtObj.map(obj => new DataDictionaryItem({ ...obj }));
          dataDictionary.push(new DataDictionary({ dataType: dt, data: dataTypeItems }));
        });
        return dataDictionary;
      }) // transform to ProjectModel
    );
  }

  private getRolesAndPermissionsDataHelper(filePath: string): Observable<IRole[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['rolesAndPermissions'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const roles = pData.map(obj => new Role({ ...obj }));
        return roles;
      })
    );
  }

  private getTenantTypeHelper(filePath: string): Observable<ITenantType[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['tenantTypes'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const tenantTypes = pData.map(obj => new TenantType({ ...obj }));
        tenantTypes.forEach(t => {
          if (t.sections && Array.isArray(t.sections)) {
            t.sections = t.sections.map(s => new TenantSection({ ...s }));
          }
        });
        return tenantTypes;
      })
    );
  }

  private getCountrySettingsHelper(filePath: string): Observable<ICountrySettings[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        if (data === undefined || (Array.isArray(data) && data.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const result = data.map(obj => new CountrySettings({ ...obj }));
        return result;
      })
    );
  }

  private getCountryHelper(filePath: string): Observable<ICountry[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        if (data === undefined || (Array.isArray(data) && data.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const result = data.map(obj => new Country({ ...obj }));
        return result;
      })
    );
  }

  private getCurrencyHelper(filePath: string): Observable<ICurrency[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        if (data === undefined || (Array.isArray(data) && data.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const result = data.map(obj => new Currency({ ...obj }));
        return result;
      })
    );
  }

  private getLanguageHelper(filePath: string): Observable<ILanguage[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        if (data === undefined || (Array.isArray(data) && data.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const result = data.map(obj => new Language({ ...obj }));
        return result;
      })
    );
  }

  //
  private getSeedTagsHelper(filePath: string): Observable<ITag[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['tags'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const parsedData = pData.map(obj => new Tag({ ...obj }));
        return parsedData;
      })
    );
  }

  private getSeedPushNotificationHelper(filePath: string): Observable<IPushNotificationMessage[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['pushNotifications'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const parsedData = pData.map(obj => new PushNotificationMessage({ ...obj }));
        return parsedData;
      })
    );
  }
  private getSeedContentCategoryHelper(filePath: string): Observable<IContentCategory[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['contentCategories'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const parsedData = pData.map(obj => new ContentCategory({ ...obj }));
        return parsedData;
      })
    );
  }

  private getSeedMediaContentHelper(filePath: string): Observable<IMediaContent[]> {
    return this.http.get(filePath).pipe(
      map((data: any) => {
        const pData = data['mediacontents'] as any[];
        if (pData === undefined || (Array.isArray(pData) && pData.length === 0)) {
          throw new Error('No items returned, URL: ' + 'url');
        }
        const parsedData = pData.map(obj => new MediaContent({ ...obj }));
        return parsedData;
      })
    );
  }
}
