import { IResource, IFileContent, ISerializer, IAuditData, ILocaleProp, LocaleProp } from '@koomzo/coremodel';
import * as util from '@koomzo/commonutil';

export interface IUser extends IResource, IAuditData {
  idToken?: string;
  pushToken?: string;
  /** could be email/mobile number/..
   * our mobile backend; firebase; beside social OAuth, only supports email/mobile number authentication,
   * our app users mostly deal with average literate users considering virtually everyone has a mobile phone.
   * we want to use a mobile number; due to the limited 10K free quota, we will use mobile number
   * as email(appending a @domainname to the number behind the scene),
   * we shall use the mobile number for phone number verfication only
   */
  username: string;
  password?: string;
  email?: string;
  mobile?: string;
  isMobileVerified?: boolean;
  givennames?: string;
  lastname?: string;
  photoURL?: string;
  displayName?: string;
  isServiceProvider?: boolean;
  roles?: string[];
  language?: string;
  isAdmin: boolean;
  hasAccess: (roles?: string[]) => boolean;

  /** in some application each user must belong to a tenant, while in others, only provider users need to
   * belong to a tenant while the consumer users on the other side don't belong to any tenant
   */
  //tenantCode?: string;
  /** */
  tenantId?: string;
  tenantSiteId?: string;
  // simpleTenantId: string;

  /**
   * @property{authProviderId: verify if user loggin in through social oauth(ex facebook, google), traditional username-password
   * mobile phone auth}
   */
  authProviderId: string;

  subscribedTags?: string[];

  initProviderUser: () => void;
  initConsumerUser: () => void;
  initTenantOwnerUser: () => void;
}

export enum AuthType {
  facebook = 'facebook',
  google = 'google',
  UsernamePassword = 'usernamePassword',
  mobile = 'mobile'
}

export class User implements IUser {
  public id?: string;
  public idToken?: string;
  public pushToken?: string;
  public username: string;
  public authProviderId: string;
  public password?: string;
  public email?: string;
  public mobile?: string;
  public isMobileVerified?: boolean;
  public givennames?: string;
  public lastname?: string;
  public photoURL?: string;
  public photo?: IFileContent;
  public displayName?: string;
  public isServiceProvider?: boolean;
  public roles?: string[];
  public language?: string;
  //public isAdmin?: boolean;
  // public tenantCode?: string;
  public tenantId?: string;
  public tenantSiteId?: string;

  // used for topics push notifications, initialized from a separate UI, or User edit form
  public subscribedTags?: string[];
  public createdAt?: Date;
  public updatedAt?: Date;

  //audit props
  public updatedByMobile?: string;
  public updatedByEmail?: string;

  public execute?: () => void;

  constructor(value: {
    username: string;
    authProviderId?: string;
    id?: string;
    idToken?: string;
    pushToken?: string;
    password?: string;
    email?: string;
    mobile?: string;
    isMobileVerified?: boolean;
    givennames?: string;
    lastname?: string;
    photoURL?: string;
    photo?: IFileContent;
    displayName?: string;
    isServiceProvider?: boolean;
    roles?: string[];
    // tenantCode?: string;
    tenantId?: string;
    tenantSiteId?: string;
    subscribedTags?: string[];
    createdAt?: Date;
  }) {
    this.username = value.username;
    this.id = value.id;
    this.idToken = value.idToken;
    this.pushToken = value.pushToken;
    this.password = value.password;
    this.email = value.email;
    this.mobile = value.mobile;
    this.isMobileVerified = value.isMobileVerified;
    this.givennames = value.givennames;
    this.lastname = value.lastname;
    this.photoURL = value.photoURL;
    this.photo = value.photo;
    this.displayName = value.displayName;
    this.isServiceProvider = value.isServiceProvider;
    this.authProviderId = value.authProviderId;
    this.roles = value.roles || [];
    //this.tenantCode = value.tenantCode;
    this.tenantId = value.tenantId;
    this.tenantSiteId = value.tenantSiteId;
    this.subscribedTags = value.subscribedTags;
    this.createdAt = value.createdAt;

    this.execute = (): void => {
      if (!this.id) {
        this.createdAt = new Date();
        this.updatedAt = new Date();

        if (!util.isNullOrUndefined(this.password)) {
          this.password = this.lastname;
        }

        if (!util.isNullOrUndefined(this.authProviderId)) {
          this.authProviderId = AuthType.UsernamePassword;
        }
      }

      if (this.id) {
        this.updatedAt = new Date();
      }

      this.roles = this.roles || ['User'];
      this.language = this.language || 'en';
      this.isServiceProvider = this.isServiceProvider || false;
      util.nullifyUndefinedlProps(this);
      return;
    };
  }

  // user has administrator permission
  get isAdmin(): boolean {
    return this.roles.some(r => r.indexOf('Administrator') > 0);
  }

  hasAccess = (roles?: string[]): boolean => {
    if (util.isNullOrUndefined(roles) || roles.length === 0) {
      return this.isAdmin;
    } else {
      //note users with GlobalAdministrator role are platform owners, and have all permissions
      return this.roles.includes('GlobalAdministrator') || roles.some(r => this.roles.includes(r));
    }
  };

  initProviderUser = (): void => {
    this.isServiceProvider = true;
    this.roles = ['Administrator'];
  };

  initConsumerUser = (): void => {
    this.isServiceProvider = false;
    this.roles = ['User'];
  };

  initTenantOwnerUser = (): void => {
    this.isServiceProvider = true;
    this.roles = ['GlobalAdministrator'];
  };

  // execute = (): void => {
  //   if (!this.id) {
  //     this.createdAt = new Date();
  //     this.updated = new Date();
  //   }

  //   if (this.id) {
  //     this.updated = new Date();
  //   }

  //   util.nullifyUndefinedlProps(this);
  //   this.roles = this.roles || ['User'];
  //   this.language = this.language || 'english';
  //   this.isServiceProvider = this.isServiceProvider || false;
  //   return;
  // };
}

export class UserSerializer implements ISerializer {
  fromJson(json: any): User {
    const model = new User({ ...json });
    return model;
  }

  toJson(model: User): any {
    return { ...model };
  }
}

export interface IRole extends IResource {
  name: any; //
  code: string;
  description?: string | ILocaleProp;
}

export class Role implements IRole {
  public name: ILocaleProp;
  public code: string;
  public description?: ILocaleProp;
  public id?: string;
  public displayOrder?: number;
  public createdAt: Date;
  public updatedAt: Date;

  constructor(value: { name: any; code: string; id?: string; description?: ILocaleProp; displayOrder?: number; createdAt?: Date }) {
    const localeNameProp = new LocaleProp({ ...value.name });
    this.name = localeNameProp;
    this.code = util.parseStringToUrl(localeNameProp.en);
    this.id = util.parseStringToUrl(localeNameProp.en);
    this.description = new LocaleProp({ ...value.description });
    this.displayOrder = value.displayOrder;
    this.createdAt = value.createdAt;
  }

  execute(): void {
    if (!this.id) {
      this.createdAt = new Date();
    } else {
      this.updatedAt = new Date();
    }
    return;
  }
}

export class RoleSerializer implements ISerializer {
  fromJson(json: any): Role {
    const model = new Role({ ...json });
    return model;
  }

  toJson(model: Role): any {
    model.execute();
    let jmodel = { ...model, name: model.name.toObject(), description: model.description.toObject() };
    return jmodel;
  }
}

export interface IUniqueUsername extends IResource {
  username: string;
}

export class UniqueUsername implements IUniqueUsername {
  public username: string;
  public id: string;

  constructor(value: { username: string; id: string }) {
    this.id = value.id;
    this.username = value.username;
  }
}

export class UniqueUsernameSerializer implements ISerializer {
  fromJson(json: any): UniqueUsername {
    const model = new UniqueUsername({ ...json });
    return model;
  }

  toJson(model: UniqueUsername): any {
    return { ...model };
  }
}
