import {
  IUser,
  ITenantType,
  IPlatformSetup,
  ITenant,
} from "@koomzo/commonmodel";
import { State, Action, StateContext, Selector } from "@ngxs/store";
import {
  SetCurrentUser,
  ChangeAppTheme,
  ChangeAppLang,
  LoadPages,
  LoadDataDictionary,
  SetPushToken,
  LoadLookupData,
  LoadSeedData,
  LoadCountryList,
  LoadLanguageList,
  LoadLocalizationSettingsList,
  LoadCurrencyList,
  SetLocalizationSettings,
} from "./app.actions";
import { tap, map, first, delay, catchError } from "rxjs/operators";
import { IPage } from "../models/page.model";
import {
  IDataDictionary,
  IDataDictionaryStateModel,
  ILanguage,
  ICountrySettings,
  ICountry,
  ICurrency,
} from "@koomzo/coremodel";
import {
  JsonConfigService,
  LanguageService,
  DataDictionaryDbService,
  InternalizationService,
  UsersDbService,
} from "@shared/services/index";
import { of, from } from "rxjs";
import { Injectable } from "@angular/core";

export interface IAppStateModel {
  tenantTypes: ITenantType[];
  currentUser: IUser;
  currentTenant: ITenant;
  appLang: string;
  pages: IPage[];
  lookupData: IDataDictionary[];
  countryList: ICountry[];
  currencyList: ICurrency[];
  languageList: ILanguage[];
  localizationSettingsList: ICountrySettings[];
  currentLocalizationSettings: ICountrySettings;
  hasInitializedSubscribedTopics: boolean;
  tenantCode?: string;
  tenantSiteCode?: string;
  pushToken?: string;
  platformSetup?: IPlatformSetup;
  seedDataLoaded: boolean;
}

@State<IAppStateModel>({
  name: "app",
  defaults: {
    tenantTypes: [],
    currentUser: null,
    currentTenant: null,
    appLang: LanguageService.DEFAULT_APP_LANGUAGE,
    pages: [],
    lookupData: [],
    countryList: [],
    currencyList: [],
    languageList: [],
    localizationSettingsList: [],
    currentLocalizationSettings: null,
    hasInitializedSubscribedTopics: false,
    tenantCode: null,
    tenantSiteCode: null,
    pushToken: null,
    platformSetup: null,
    seedDataLoaded: false,
  },
})
@Injectable()
export class AppState {
  @Selector()
  static AppLang(state: IAppStateModel) {
    return state.appLang || LanguageService.DEFAULT_APP_LANGUAGE; //to update default language
  }

  constructor(
    private usersDbService: UsersDbService,
    private languageService: LanguageService,
    private jsonSrv: JsonConfigService,
    private dataDictionaryService: DataDictionaryDbService,
    private internalizationService: InternalizationService
  ) {}

  ngxsOnInit(ctx?: StateContext<AppState>) {
    console.log("AppState:ngxsOnInit");
    //ctx.dispatch(new GetAnimals());
    // ctx.dispatch(new LoadContentCategoryList());
  }

  // @Action(SetCurrentUser)
  // setUsername({ patchState }: StateContext<IAppStateModel>, { payload }: SetCurrentUser) {
  //   patchState({ currentUser: payload });
  // }

  @Action(ChangeAppLang)
  changeAppLang(
    { patchState }: StateContext<IAppStateModel>,
    { payload }: ChangeAppLang
  ) {
    console.log("change language: " + payload);
    this.languageService.setLanguage(payload);
    patchState({ appLang: payload });
  }

  @Action(LoadPages)
  loadPages(ctx: StateContext<IAppStateModel>, action: LoadPages) {
    return from(this.jsonSrv.getPageConfig1()).pipe(
      tap((result: IPage[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, pages: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadLookupData)
  loadLookupData(ctx: StateContext<IAppStateModel>) {
    return from(this.jsonSrv.getDataDictionary()).pipe(
      tap((result: IDataDictionary[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, lookupData: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadCountryList)
  loadCountryList(ctx: StateContext<IAppStateModel>) {
    return from(this.jsonSrv.getCountryList()).pipe(
      tap((result: ICountry[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, countryList: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadLanguageList)
  loadLanguageList(ctx: StateContext<IAppStateModel>) {
    return from(this.jsonSrv.getLanguageList()).pipe(
      tap((result: ILanguage[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, languageList: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadCurrencyList)
  loadCurrencyList(ctx: StateContext<IAppStateModel>) {
    return from(this.jsonSrv.getCurrencyList()).pipe(
      tap((result: ICurrency[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, currencyList: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadLocalizationSettingsList)
  loadCountrySettings(ctx: StateContext<IAppStateModel>) {
    return from(this.jsonSrv.getCountrySettingsList()).pipe(
      tap((result: ICountrySettings[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, localizationSettingsList: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(SetLocalizationSettings)
  setLocalizationSettings(
    ctx: StateContext<IAppStateModel>,
    { payload }: SetLocalizationSettings
  ) {
    return from(
      this.internalizationService.getLocalizationSettings(payload)
    ).pipe(
      tap((result: ICountrySettings) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, currentLocalizationSettings: result });
      }),
      catchError((err) => {
        console.log("caught rethrown error, providing fallback value", err);
        return of([]);
      })
    );
  }

  @Action(LoadDataDictionary)
  loadDataDictionary(ctx: StateContext<IDataDictionaryStateModel>) {
    return this.dataDictionaryService.get().pipe(
      tap((result: IDataDictionary[]) => {
        const state = ctx.getState();
        ctx.patchState({ ...state, dataDictionary: result });
      })
    );
  }

  @Action(SetPushToken)
  SetUserPushToken(
    { patchState }: StateContext<IAppStateModel>,
    { payload }: SetPushToken
  ) {
    console.log("initializing PUSH TOKEN: " + payload);
    patchState({ pushToken: payload });
  }
}
