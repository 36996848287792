import * as util from '@koomzo/commonutil';
import { ILocaleProp, LocaleProp } from '@koomzo/coremodel';
import { isObject } from '@koomzo/commonutil';
export interface IPage {
  name: string;
  title?: string | ILocaleProp;
  parsedTitle: string;
  subTitle?: string | ILocaleProp;
  parsedSubtitle: string;
  url: string;
  showInNav: boolean;
  showInHome: boolean;
  displayOrder: number;
  icon?: string;
  imageUrl?: string;
  children?: IPage[];
  roles?: string[];
  isAdmin?: boolean;
  isClient?: boolean;
  // UI state
  open?: boolean;
}

export class Page implements IPage {
  name: string;
  url: string;
  title?: string | ILocaleProp;
  subTitle?: string | ILocaleProp;
  subTitleLabel?: string;
  showInNav: boolean;
  showInHome: boolean;
  displayOrder: number;
  icon?: string;
  imageUrl?: string;
  open: boolean;
  children?: IPage[];
  roles?: string[];
  isAdmin?: boolean;
  isClient?: boolean;

  constructor(value: {
    name: string;
    url: string;
    title?: string | ILocaleProp;
    subTitle?: string | ILocaleProp;
    showInNav: boolean;
    showInHome: boolean;
    displayOrder: number;
    icon?: string;
    imageUrl?: string;
    roles?: string[];
    isAdmin?: boolean;
    isClient?: boolean;
    children?: IPage[];
    open?: boolean;
  }) {
    this.name = value.name;
    this.url = value.url;
    this.title = isObject(value.title) ? new LocaleProp({ ...(value.title as any) }) : value.title;
    this.subTitle = isObject(value.subTitle) ? new LocaleProp({ ...(value.subTitle as any) }) : value.subTitle;
    this.showInNav = value.showInNav;
    this.showInHome = value.showInHome;
    this.displayOrder = value.displayOrder;
    this.icon = value.icon;
    this.imageUrl = value.imageUrl;
    this.roles = value.roles;
    this.isAdmin = util.isBoolean(value.isAdmin) ? value.isAdmin : false;
    this.isClient = util.isBoolean(value.isClient) ? value.isClient : false;

    if (value.children) {
      const childPages = [];
      value.children.forEach(p => {
        childPages.push(new Page({ ...p }));
      });

      this.children = childPages;
    }
    this.open = util.isBoolean(value.open) ? value.open : false;
  }

  get parsedTitle(): string {
    if (this.title instanceof LocaleProp) {
      return this.title.getText();
    } else {
      return this.title as string;
    }
  }

  get parsedSubtitle(): string {
    if (this.subTitle instanceof LocaleProp) {
      return this.subTitle.getText();
    } else {
      return this.subTitle as string;
    }
  }
}
