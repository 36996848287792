import { IResource, ISerializer, ILocaleProp, LocaleProp } from '@koomzo/coremodel';
import * as util from '@koomzo/commonutil';

export interface IContentCategory extends IResource {
  tenantId?: string;
  name: ILocaleProp;
  code: string;
  tags: string[]; //ITag[];
}

export class ContentCategory implements IContentCategory {
  id: string;
  tenantId?: string;
  name: ILocaleProp;
  code: string;
  tags: string[]; //ITag[];

  constructor(value: { name: string; tags: string[]; id?: string; tenantId?: string }) {
    const localeName = new LocaleProp({ ...(value.name as any) });
    this.name = localeName;
    this.code = util.parseStringToUrl(localeName.en);
    this.id = value.id || util.parseStringToUrl(localeName.en);
    this.tags = value.tags;
    this.tenantId = value.tenantId;
  }
}

export class ContentCategorySerializer implements ISerializer {
  fromJson(json: any): IContentCategory {
    const model = new ContentCategory({ ...json });
    return model;
  }

  toJson(model: ContentCategory): any {
    return { ...model, name: model.name.toObject() };
  }
}
