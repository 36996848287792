import { IResource, ISerializer, IFileContent } from '@koomzo/coremodel';
import * as util from '@koomzo/commonutil';

/**
 * @description {
 * class represents base model for provisioning of content(image/video/audio with text) on backend to push to mobile users
 *  includes campaign content specific properties: startDate, endDate
 * and credits for sponsored data specific use cases
 *
 * }
 *
 * @export
 * @interface IMediaContent
 * @extends {IResource}
 */
export interface IMediaContent extends IResource {
  tenantId?: string;
  userId?: string;
  title: string;
  author: string;
  description?: string;

  imageUrl?: string;
  //custom object contains all the relevant information about a media content
  content?: IFileContent;

  //enum: urbantrendz,brandnew...
  contentCategory: string;
  cmsStep: string;
  created: Date;
  updated: Date;
  selected?: boolean;

  //media content for campaign specific properties; a campaign should have a start-end date
  startDate?: Date;
  endDate?: Date;
  /** spefific use case when using campaign content for sponsored data: specific properties; */
  credits?: number;
  isSponsoredData?: () => boolean;
}

export class MediaContent implements IMediaContent {
  tenantId?: string;
  userId?: string;
  title: string;
  author: string;
  description?: string;
  publishStatus: string;
  imageUrl?: string;
  content?: IFileContent;
  contentCategory: string;
  cmsStep: string;
  created: Date;
  updated: Date;
  id: string;
  selected?: boolean;

  startDate?: Date;
  endDate?: Date;
  credits?: number;

  constructor(value: { userId?: string; tenantId?: string; title: string; author: string; description?: string; imageUrl?: string; content?: IFileContent; contentCategory: string; cmsStep: string; created: Date; updated: Date; id: string; startDate?: Date; endDate?: Date; credits?: number }) {
    this.tenantId = value.tenantId;
    this.title = value.title;
    this.author = value.author;
    this.description = value.description;
    this.imageUrl = value.imageUrl;
    this.content = value.content;
    this.contentCategory = value.contentCategory;
    this.cmsStep = value.cmsStep || 'pending';
    this.id = value.id;
    this.startDate = value.startDate;
    this.endDate = value.endDate;
    this.credits = value.credits;
  }

  isSponsoredData(): boolean {
    return !util.isNullOrUndefined(this.startDate) && !util.isNullOrUndefined(this.endDate) && !util.isNullOrUndefined(this.credits);
  }
}

export class MediaContentSerializer implements ISerializer {
  fromJson(json: any): IMediaContent {
    const model = new MediaContent({ ...json });
    return model;
  }

  toJson(jmodel: MediaContent): any {
    const model = new MediaContent({ ...jmodel });
    // model.execute();
    return { ...model };
  }
}
