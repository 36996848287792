import { IResource } from './resource.interface';
import { ISerializer } from './serializer.model';
import * as util from '@koomzo/commonutil';
import { ILocaleProp, LocaleProp, LocalePropSerializer } from './locale-prop.model';

export interface ITag extends IResource {
  tenantId?: string;
  name: ILocaleProp;
  code: string;
  isSelected: boolean;
  group?: string; //optional group to which tag belongs
}

export class Tag implements ITag {
  id: string;
  tenantId?: string;
  name: ILocaleProp;
  code: string;
  isSelected: boolean;
  group?: string;
  constructor(value: { name: string; isSelected?: boolean; group?: string; tenantId?: string; id?: string }) {
    const localeName = new LocaleProp({ ...(value.name as any) });
    this.name = localeName;
    this.code = util.parseStringToUrl(localeName.en);
    this.id = value.id || util.parseStringToUrl(localeName.en);
    this.group = value.group;
    this.isSelected = value.isSelected;
  }
}

export class TagSerializer implements ISerializer {
  fromJson(json: any): Tag {
    const tag = new Tag({ ...json });
    return tag;
  }

  toJson(model: Tag): any {
    const localePropSerrializer = new LocalePropSerializer();
    return { ...model, name: localePropSerrializer.toJson(model.name) };
  }
}

export interface IUserSubscribedTags extends IResource {
  //userId: string;
  tags: string[];
}

export class UserSubscribedTags implements IUserSubscribedTags {
  id: string;
  // userId: string;
  tags: string[];

  constructor(value: { id: string; tags: string[] }) {
    this.id = value.id;
    //this.userId = value.userId;
    this.tags = value.tags;
  }
}

export class UserSubscribedTagsSerializer implements ISerializer {
  fromJson(json: any): IUserSubscribedTags {
    const model = new UserSubscribedTags({ ...json });
    return model;
  }

  toJson(model: IUserSubscribedTags): any {
    return {
      id: model.id,
      // userId: model.userId,
      tags: model.tags
    };
  }
}
