export interface ICountrySettings {
  countryCode: string;
  countryCallingCode: string;
  mobileNumberMask: string[];
  languagesCode: string;
  zipCodeOrPostCodeOrPostbox: string; //zipcode, postcode, pobox
  zipCodeOrPostCodeOrPostboxMask: string;
  addressingSchemeCode: string;
  defaultTheme: string;
  paymentChannelCodes: string[];
  smsProviderCodes: string[];
  emailProviderCodes: string[];
  languages?: ILanguage[];
  currencies?: ICurrency[];
  countries?: ICountry[];
}

export class CountrySettings implements ICountrySettings {
  public countryCode: string;
  public countryCallingCode: string;
  public mobileNumberMask: string[];
  public languagesCode: string;
  public zipCodeOrPostCodeOrPostbox: string; //zipcode, postcode, pobox
  public zipCodeOrPostCodeOrPostboxMask: string;
  public addressingSchemeCode: string;
  public defaultTheme: string;
  public paymentChannelCodes: string[];
  public smsProviderCodes: string[];
  public emailProviderCodes: string[];
  public languages: ILanguage[];
  public currencies: ICurrency[];
  public countries?: ICountry[];
  constructor(value: {
    countryIsoCode: string;
    countryCallingCode: string;
    mobileNumberMask: string[];
    languagesCode: string;
    zipCodeOrPostCodeOrPostbox: string; //zipcode, postcode, pobox
    zipCodeOrPostCodeOrPostboxMask: string;
    addressingSchemeCode: string;
    defaultTheme: string;
    paymentChannelCodes: string[];
    smsProviderCodes: string[];
    emailProviderCodes: string[];
    languages?: ILanguage[];
    currencies?: ICurrency[];
    countries?: ICountry[];
  }) {
    this.countryCode = value.countryIsoCode;
    this.countryCallingCode = value.countryCallingCode;
    this.mobileNumberMask = value.mobileNumberMask;
    this.languagesCode = value.languagesCode;
    this.zipCodeOrPostCodeOrPostbox = value.zipCodeOrPostCodeOrPostbox;
    this.zipCodeOrPostCodeOrPostboxMask = value.zipCodeOrPostCodeOrPostboxMask;
    this.addressingSchemeCode = value.addressingSchemeCode;
    this.defaultTheme = value.defaultTheme;
    this.paymentChannelCodes = value.paymentChannelCodes;
    this.smsProviderCodes = value.smsProviderCodes;
    this.emailProviderCodes = value.emailProviderCodes;
    this.languages = value.languages;
    this.currencies = value.currencies;
    this.countries = value.countries;
  }
}

export interface ICountry {
  name: string;
  nativeName?: string;
  code: string;
  callingCode: string;
  flagname: string;
}

export class Country implements ICountry {
  public name: string;
  public nativeName?: string;
  public code: string;
  public callingCode: string;
  public flagname: string;
  constructor(value: { name: string; nativeName?: string; code: string; callingCode: string; flagname: string }) {
    this.name = value.name;
    this.code = value.code;
    this.nativeName = value.nativeName;
    this.callingCode = value.callingCode;
    this.flagname = value.flagname;
  }
}

export interface ILanguage {
  name: string;
  nativeName?: string;
  code: string;
}

export class Language implements ILanguage {
  public name: string;
  public nativeName?: string;
  public code: string;
  constructor(value: { name: string; nativeName?: string; code: string }) {
    this.name = value.name;
    this.code = value.code;
    this.nativeName = value.nativeName;
  }
}
export interface ICurrency {
  name: string;
  code: string;
  symbol: string;
  fractionSize: number;
  template?: string;
  rtl: boolean;
  uniqSymbol?: boolean;
}

export class Currency implements ICurrency {
  public name: string;
  public code: string;
  public symbol: string;
  public fractionSize: number;
  public template?: string;
  public rtl: boolean;
  public uniqSymbol?: boolean;
  constructor(value: {
    name: string;
    code: string;
    symbol: string;
    fractionSize: number;
    template?: string;
    rtl: boolean;
    uniqSymbol?: boolean;
  }) {
    this.name = value.name;
    this.code = value.code;
    this.symbol = value.symbol;
    this.fractionSize = value.fractionSize;
    this.template = value.template;
    this.rtl = value.rtl;
    this.uniqSymbol = value.uniqSymbol;
  }
}

export interface ITax {
  name: string;
}
