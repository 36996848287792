import { IUser, ITenant } from "@koomzo/commonmodel";
export class SetCurrentUser {
  static readonly type = "[app] set current user";
  constructor(public payload: { id: string }) {}
}

export class ChangeAppTheme {
  static readonly type = "[app] change app theme";
  constructor(public payload: string) {}
}

export class ChangeAppLang {
  static readonly type = "[app] change app language";
  constructor(public payload: string) {}
}

export class LoadPages {
  static readonly type = "[app] load pages";
}

/**note this data is provisioned by the DataDictionary Admin module */
export class LoadDataDictionary {
  static readonly type = "[app] load Lookup data dictionary";
}

export class LoadLookupData {
  static readonly type = "[app] load Lookup data";
}

export class LoadCountryList {
  static readonly type = "[app] load country list";
}

export class LoadLanguageList {
  static readonly type = "[app] load language list";
}

export class LoadCurrencyList {
  static readonly type = "[app] load currency list";
}

export class LoadLocalizationSettingsList {
  static readonly type = "[app] load country settings";
}

export class SetLocalizationSettings {
  static readonly type = "[app] set localization settings";
  constructor(public payload: string) {}
}

export class SetPushToken {
  static readonly type = "[app] set user push token";
  constructor(public payload: string) {}
}

export class LoadSeedData {
  static readonly type = "[app] load seed data";
}
