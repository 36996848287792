import { IResource } from './resource.interface';
import { ISerializer } from './serializer.model';
import { IOption } from './option.interface';
import * as util from '@koomzo/commonutil';
import { ILocaleProp, LocaleProp, LocalePropSerializer } from './locale-prop.model';

export interface IDataDictionaryItem {
  name: ILocaleProp;
  code: string;
  description?: string;
  displayOrder?: number;
  convertToOption?: () => IOption;
}

export interface IDataDictionary extends IResource {
  dataType: string;
  data: IDataDictionaryItem[];
}

export class DataDictionary implements IDataDictionary {
  public id?: string;
  public dataType: string;
  public data: IDataDictionaryItem[];
  public createdAt: Date;
  public updatedAt: Date;

  constructor(value: { dataType: string; data: IDataDictionaryItem[]; id?: string; createdAt?: Date }) {
    this.id = value.id || util.parseStringToUrl(value.dataType);
    this.dataType = value.dataType;
    this.data = value.data;
    this.createdAt = value.createdAt;

    //initialize the code property for each item. we can do this ac
    this.data.forEach(item => {
      if (!item.code) {
        if (util.isObject(item.name)) {
          const localeName = new LocaleProp({ ...(item.name as any) });
          //code is built out of just the en component
          item.code = util.parseStringToUrl(localeName.en);
        }
        //  else {
        //   item.code = util.parseStringToUrl(item.name as string);
        // }
      }
    });
  }

  execute(): void {
    if (!this.id) {
      this.createdAt = new Date();
    } else {
      this.updatedAt = new Date();
    }
    util.nullifyUndefinedlProps(this);
    return;
  }
}

export class DataDictionaryItem implements IDataDictionaryItem {
  name: ILocaleProp;
  code: string;
  description?: string;
  displayOrder?: number;

  constructor(value: { name: any; code: string; description?: string; displayOrder?: number }) {
    this.name = new LocaleProp({ ...value.name });
    this.code = value.code;
    this.description = value.description;
    this.displayOrder = value.displayOrder;
  }

  convertToOption? = (): IOption => {
    const result: IOption = {
      name: this.name,
      code: this.code,
      description: this.description,
      isSelected: false
    };

    return result;
  };
}

export class DataDictionaryItemSerializer implements ISerializer {
  fromJson(json: any): DataDictionaryItem {
    const model = new DataDictionaryItem({ ...json });
    return model;
  }

  toJson(model: DataDictionaryItem): any {
    const localePropSerrializer = new LocalePropSerializer();
    return { ...model, name: localePropSerrializer.toJson(model.name) };
  }
}

export class DataDictionarySerializer implements ISerializer {
  fromJson(json: any): DataDictionary {
    const model = new DataDictionary({ ...json });
    return model;
  }

  toJson(model: DataDictionary): any {
    model.execute();
    const jmodel = { ...model };
    const ddItemSerializer = new DataDictionaryItemSerializer();
    const jdata: any[] = [];
    jmodel.data.forEach(dItem => {
      const objItem = ddItemSerializer.toJson(dItem);
      util.nullifyUndefinedlProps(objItem);
      jdata.push(objItem);
    });
    jmodel.data = jdata;
    return jmodel;
  }
}

// store state model
export interface IDataDictionaryStateModel {
  hasLoaded: boolean;
  dataDictionary: IDataDictionary[];
}
