import { FormatCellPipe } from './format-cell.pipe';
import { MillionsPipe } from './millions.pipe';
import { ArraySortPipe } from './array-sort.pipe';
import { InlineTranslatePipe } from './inline-translate.pipe';

export const pipes: any[] = [FormatCellPipe, MillionsPipe, ArraySortPipe, InlineTranslatePipe];

export * from './format-cell.pipe';
export * from './millions.pipe';
export * from './array-sort.pipe';
export * from './inline-translate.pipe';
