import { INotificationDataMessage } from '@koomzo/commonmodel';

export interface IGeoLocation {
  alt?: number;
  lng: number;
  lat: number;
  //location name
  name?: string;
}

export class GeoLocationModel implements IGeoLocation {
  alt?: number;
  lng: number;
  lat: number;
  name?: string;
  constructor(lat: number, lng: number, name?: string) {
    this.lat = lat;
    this.lng = lng;
    this.name = name;
  }
}

export interface ICustomMapMarkerWithLabel {
  lat: number;
  lng: number;
  isDraggable?: boolean;
  payload?: INotificationDataMessage[];
  labelContent?: string;
  labelClass?: string;
  colorNameValuePair?: string;
  iconUrl?: string;
  colorName: string;
  colorValue: string;
}

export class CustomMapMarkerWithLabel implements ICustomMapMarkerWithLabel {
  lat: number;
  lng: number;
  isDraggable?: boolean;
  payload?: INotificationDataMessage[];
  labelContent?: string;
  labelClass?: string;
  colorNameValuePair?: string;
  iconUrl?: string;
  get colorName(): string {
    return this.colorNameValuePair.split('_')[0];
  }
  get colorValue(): string {
    return this.colorNameValuePair.split('_')[1];
  }
  constructor(value: {
    lat: number;
    lng: number;
    isDraggable?: boolean;
    payload?: INotificationDataMessage[];
    labelContent?: string;
    labelClass?: string;
    colorNameValuePair?: string;
    iconUrl?: string;
  }) {
    this.lat = value.lat;
    this.lng = value.lng;
    this.isDraggable = value.isDraggable;
    this.payload = value.payload;
    this.labelContent = value.labelContent;
    this.labelClass = value.labelClass;
    this.colorNameValuePair = value.colorNameValuePair || 'default_#d7d8da';
    this.iconUrl = value.iconUrl || 'http://image.flaticon.com/icons/svg/252/252025.svg';
  }
}
