import { NgModule } from "@angular/core";
import { PreloadAllModules, RouterModule, Routes } from "@angular/router";
import { AppPreloadingStrategy } from "./app-routing.loader";
import * as env from "@env/environment";

const routes: Routes = [
  {
    path: "",
    loadChildren: () =>
      import("./layout-page/layout-page.module").then(
        (m) => m.LayoutPageModule
      ),
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomePageModule),
  },
  {
    path: "how-it-works",
    loadChildren: () =>
      import("./pages/how-it-works/how-it-works.module").then(
        (m) => m.HowItWorksPageModule
      ),
  },
  {
    path: "features",
    loadChildren: () =>
      import("./pages/features/features.module").then(
        (m) => m.FeaturesPageModule
      ),
  },
  {
    path: "service",
    loadChildren: () =>
      import("./pages/service/service.module").then((m) => m.ServicePageModule),
  },
  {
    path: "products",
    loadChildren: () =>
      import("./pages/products/products.module").then(
        (m) => m.ProductsPageModule
      ),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./pages/about-us/about-us.module").then(
        (m) => m.AboutUsPageModule
      ),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./pages/contact-us/contact-us.module").then(
        (m) => m.ContactUsPageModule
      ),
  },
  {
    path: "generic",
    loadChildren: () =>
      import("./pages/generic-page/generic.module").then(
        (m) => m.GenericPageModule
      ),
  },

  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
];

@NgModule({
  //imports: [RouterModule.forRoot(routes, { preloadingStrategy: AppPreloadingStrategy })],
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
