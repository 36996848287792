import { ISerializer } from './serializer.model';
import { IResource } from './resource.interface';

export interface ILocaleProp extends IResource {
  en: string;
  fr?: string;
  es?: string;
  currentLang?: string;
  getText: (lang?: string) => string;
  toObject: () => any;
}

export class LocaleProp implements ILocaleProp {
  id?: string;
  readonly supportedLangs: string[] = ['en', 'fr', 'es'];
  en: string;
  fr?: string;
  es?: string;
  currentLang?: string;

  constructor(value: { en: string; fr?: string; es?: string }, lang?: string) {
    this.en = value.en;
    this.fr = value.fr;
    this.es = value.es;
    this.currentLang = lang || 'en';
  }

  getText = (lang?: string): string => {
    let value = null;
    if (lang && this.supportedLangs.indexOf(lang) > 0) {
      value = this[lang];
    }

    if (this.currentLang && this.supportedLangs.indexOf(this.currentLang) > 0) {
      value = this[this.currentLang];
    }
    return value || this.en;
  };

  toObject = () => {
    return { en: this.en, fr: this.fr, es: this.es };
  };
}

export class LocalePropSerializer implements ISerializer {
  fromJson(json: any): LocaleProp {
    const model = new LocaleProp({ ...json });
    return model;
  }

  toJson(model: ILocaleProp): any {
    return { en: model.en, fr: model.fr, es: model.es };
  }
}
