import { Injector, Injectable } from '@angular/core';
import { ReplaySubject, Subject, BehaviorSubject } from 'rxjs';

/**
 * @link(https://devblogs.microsoft.com/premier-developer/angular-how-to-simplify-components-with-typescript-inheritance/)
 */
@Injectable()
export class AppInjectorService {
  private static injector: Injector;

  static setInjector(injector: Injector) {
    console.log('SETTING INJECTOR');
    AppInjectorService.injector = injector;
  }

  static getInjector(): Injector {
    console.log('READING INJECTOR');
    return AppInjectorService.injector;
  }
}

// export class AppInjectorService {
//   private static injector: Injector = undefined;
//   private static subject = new BehaviorSubject<Injector>(AppInjectorService.injector);

//   static setInjector(injector: Injector) {
//     console.log('SETTING INJECTOR');
//     AppInjectorService.injector = injector;
//     AppInjectorService.subject.next(AppInjectorService.injector);
//   }

//   static getInjector(): BehaviorSubject<Injector> {
//     console.log('READING INJECTOR');
//     return AppInjectorService.subject;
//   }
// }
