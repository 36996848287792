import { Observable } from 'rxjs';

export function isNullOrUndefined(value: any) {
  return value === undefined || value === null;
}

export function isUndefined(value: any) {
  return value === undefined;
}

export function isBlankString(value: any) {
  return value === '';
}

export function isFunction(value: any) {
  return typeof value === 'function';
}

export function isNumber(value: any): value is number {
  return typeof value === 'number';
}

export function isNumeric(value: string) {
  return /^\d+$/.test(value);
}

export function isEmail(email: string) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function isObject(x: any) {
  return x !== null && typeof x === 'object';
}

export function isString(x: any) {
  return x !== null && typeof x === 'string';
}

export function isBoolean(x: any) {
  return x !== null && typeof x === 'boolean';
}

/** used especially when posting json code from javascript code to json backend,
 * since undefined in javascript is not a valid json type
 */
export function nullifyUndefinedlProps(model: any): void {
  Object.keys(model).forEach(key => {
    if (isObject(model[key])) {
      nullifyUndefinedlProps(model[key]);
    }
    if (isUndefined(model[key])) {
      model[key] = null;
    }

    if (isNullOrUndefined(model['id'])) {
      delete model['id'];
    }

    if (isFunction(model[key])) {
      delete model[key];
    }
  });
}

/** remove all invalid json data before saving to Json Backend,
 * ex: undefined being a valid javascript type is not a valid json type,
 * also get rid of functions
 */
export function toValidJsonData(model: any): void {
  Object.keys(model).forEach(key => {
    if (isObject(model[key])) {
      toValidJsonData(model[key]);
    }
    if (isUndefined(model[key])) {
      model[key] = null;
    }

    if (isNullOrUndefined(model['id'])) {
      delete model['id'];
    }

    if (isFunction(model[key])) {
      delete model[key];
    }
  });
}

export function getFullname(name: string): { lastname: string; givennames: string } {
  const splitnames = name.split(' ');
  if (splitnames.length === 1) return { lastname: splitnames[0], givennames: '--' };

  const lastname = splitnames.pop() || '--';
  const index1 = name.lastIndexOf(' ');
  const givennames = name.substring(0, index1 + 1);
  return { lastname: lastname, givennames: givennames };
}

export function clone(value: any): any {
  if (
    !isObject(value) ||
    value instanceof RegExp ||
    value instanceof Observable ||
    /* instanceof SafeHtmlImpl */ value.changingThisBreaksApplicationSecurity
  ) {
    return value;
  }

  // if (value instanceof AbstractControl) {
  //   return null;
  // }

  if (Object.prototype.toString.call(value) === '[object Date]') {
    return new Date(value.getTime());
  }

  if (Array.isArray(value)) {
    return value.slice(0).map(v => clone(v));
  }

  const pvalue = Object.assign({}, value);
  Object.keys(pvalue).forEach(k => (pvalue[k] = clone(pvalue[k])));

  return pvalue;
}

// Speed up calls to hasOwnProperty
const hasOwnProperty = Object.prototype.hasOwnProperty;
export function isEmpty(obj: any): boolean {
  // null and undefined are "empty"
  if (obj === null) return true;

  // Assume if it has a length property with a non-zero value
  // that that property is correct.
  if (obj.length > 0) return false;
  if (obj.length === 0) return true;

  // If it isn't an object at this point
  // it is empty, but it can't be anything *but* empty
  // Is it empty?  Depends on your application.
  if (typeof obj !== 'object') return true;

  // Otherwise, does it have any properties of its own?
  // Note that this doesn't handle
  // toString and valueOf enumeration bugs in IE < 9
  for (const key in obj) {
    if (hasOwnProperty.call(obj, key)) return false;
  }

  return true;
}

//https://stackoverflow.com/questions/26156292/trim-specific-character-from-a-string
export function escapeRegExp(strToEscape: string): string {
  // Escape special characters for use in a regular expression
  return strToEscape.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
}

export function trimChar(origString: string, charToTrim: string): string {
  const pcharToTrim = escapeRegExp(charToTrim);
  const regEx = new RegExp('^[' + pcharToTrim + ']+|[' + pcharToTrim + ']+$', 'g');
  return origString.replace(regEx, '');
}

//https://stackoverflow.com/questions/263965/how-can-i-convert-a-string-to-boolean-in-javascript
export function stringToBoolean(value: string): boolean {
  switch (value.toLowerCase().trim()) {
    case 'true':
    case 'yes':
    case '1':
      return true;
    case 'false':
    case 'no':
    case '0':
    case null:
      return false;
    default:
      return Boolean(value);
  }
}

// https://stackoverflow.com/questions/1026069/how-do-i-make-the-first-letter-of-a-string-uppercase-in-javascript
export function capitalizeFirstLetter(value: string) {
  return value.charAt(0).toUpperCase() + value.slice(1);
}

/**
 * if username is not an email , we want to convert it to email format inorder to use firebase' email auth feature
 * @param username : username can be a phone number(internal format), email, or random unique Id
 */
export function formatUsernameToEmailFormat(username: string) {
  let pUsername = username;
  if (isNumeric(username.replace('+', ''))) {
    pUsername = `${username}@koomzo.com`;
  }
  if (!isNumeric(username.replace('+', '')) && !isEmail(username)) {
    pUsername = `${username}@koomzo.com`;
  }
  return pUsername;
}
