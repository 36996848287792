export const isIntegerString = (val: string): boolean => {
  const intRegex = /^\d+$/;
  return intRegex.test(val) ? true : false;
};

/**@copyright : copied from ngx-translate git project
 * Returns the language code name from the browser, e.g. "de"
 * @returns string
 */
export const getBrowserLang = (): string => {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }

  let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
  browserLang = browserLang || window.navigator.language;

  if (browserLang.indexOf('-') !== -1) {
    browserLang = browserLang.split('-')[0];
  }

  if (browserLang.indexOf('_') !== -1) {
    browserLang = browserLang.split('_')[0];
  }

  return browserLang;
};

/**@copyright : copied from ngx-translate git project
 * Returns the culture language code name from the browser, e.g. "de-DE"
 * @returns string
 */
export const getBrowserCultureLang = (): string => {
  if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
    return undefined;
  }
  let browserCultureLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
  browserCultureLang = browserCultureLang || window.navigator.language;
  return browserCultureLang;
};

export const isTrue = (val: string) => {
  if (val === 'true') {
    return true;
  }
  return false;
};

export const parseStructure = (data: any): string => {
  if (Array.isArray(data)) {
    const hasObjects = data.every(elem => elem !== typeof 'object');
    if (!hasObjects) {
      return data.join(', ');
    }
    return data
      .map(obj => {
        return obj.name ? obj.name : 'Unknown object';
      })
      .join(', ');
  }
  return data.name ? data.name : 'Unknown object';
};

export const extractPipeParams = (val: string) => {
  const params = val.split(':').slice(1);
  return params.length > 1 ? params : params[0];
};

export const isPipe = (name: string, params: string): boolean => {
  const len = name.length;
  return params.slice(0, len) === name;
};


