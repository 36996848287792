import { IResource, ISerializer } from '@koomzo/coremodel';

export interface IMessage extends IResource {
  text: string;
}

export class Message implements IMessage {
  //user id
  id: string;
  text: string;
  createdAt: Date;

  constructor(value: { userId: string; createdAt: Date; text: string }) {
    this.id = value.userId;
    this.text = value.text;
    this.createdAt = value.createdAt;
  }
}

export class MessageSerializer implements ISerializer {
  fromJson(json: any): IMessage {
    const model = new Message({ ...json });
    return model;
  }

  toJson(model: IMessage): any {
    return { ...model };
  }
}

export interface IChat extends IResource {
  ownerId: string;
  participants: string[];
  count: number;
  messages: IMessage[];
  createdAt: Date;
}

export class Chat implements IChat {
  public ownerId: string;
  public participants: string[];
  public count: number;
  public messages: IMessage[];
  public createdAt: Date;

  constructor(value: { ownerId: string; participants?: string[]; messages?: IMessage[]; createdAt: Date }) {
    this.ownerId = value.ownerId;
    this.participants = value.participants;
    this.messages = value.messages;
    this.createdAt = value.createdAt;
  }

  execute(): void {
    if (!this.messages) {
      this.createdAt = new Date();
    }
    return;
  }
}

export class ChatSerializer implements ISerializer {
  fromJson(json: any): Chat {
    const model = new Chat({ ...json });
    return model;
  }

  // toJson(model: Chat): any {
  //   model.execute();
  //   return { ...model };
  // }

  toJson(model: Chat): any {
    model.execute();
    const jmodel = { ...model };
    const ddItemSerializer = new MessageSerializer();
    const jdata: any[] = [];
    jmodel.messages.forEach(dItem => {
      const objItem = ddItemSerializer.toJson(dItem);
      //nullifyUndefinedlProps(objItem);
      jdata.push(objItem);
    });
    jmodel.messages = jdata;
    return jmodel;
  }
}
