import { IResource, ISerializer } from '@koomzo/coremodel';
import { AngularFirestore, AngularFirestoreCollection, QueryFn } from '@angular/fire/firestore';
import { map, expand, takeWhile, take, mergeMap, tap, scan } from 'rxjs/operators';
import { Observable, from, BehaviorSubject, Subscription } from 'rxjs';
import * as util from '@koomzo/commonutil';
import * as firebase from 'firebase/app';
import { BaseDbService } from './db/base-db.service';
import { Injectable } from '@angular/core';

// Options to reproduce firestore queries consistently
interface IQueryConfig {
  path: string; // path to collection
  field: string; // field to orderBy
  limit?: number; // limit per query
  reverse?: boolean; // reverse order?
  prepend?: boolean; // prepend to source?
}

export class FirebaseResourceService<T extends IResource> extends BaseDbService<T> {
  //pagination props
  // Source data
  private _done = new BehaviorSubject(false);
  private _loading = new BehaviorSubject(false);
  private _data = new BehaviorSubject([]);
  private query: IQueryConfig;

  // Observable data
  public data: Observable<T[]>;
  public done: Observable<boolean> = this._done.asObservable();
  public loading: Observable<boolean> = this._loading.asObservable();
  protected defaultPath: string;

  constructor(protected afs: AngularFirestore, protected serializer: ISerializer) {
    super();
  }

  /// Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  public get(path?: string, predicate?: any): Observable<T[]> {
    return this.collection$(path, predicate);
  }
  public getById(id: string): Observable<T> {
    return this.doc$(id);
  }
  public addOrUpdate(obj: any): Promise<T> {
    return this.updateAt(obj);
  }
  public add(obj: T[]): Promise<void> {
    return this.addList(obj);
  }

  public delete(id: string): Promise<void> {
    const path = `${this.defaultPath}/${id}`;
    return this.afs.doc(path).delete();
  }

  public deleteList(queryFn: any): Observable<any> {
    return this.deleteCollection(queryFn);
  }

  private collection$(path?: string, query?): Observable<T[]> {
    const usePath = path || this.defaultPath;
    return this.afs
      .collection<T>(usePath, query)
      .snapshotChanges()
      .pipe(
        map(actions => {
          return actions.map(a => {
            const data: Object = a.payload.doc.data();
            const id = a.payload.doc.id;
            // console.log('collection query');
            // console.log(data);
            const val = { id, ...data };
            return this.serializer.fromJson(val) as T;
          });
        })
      );
  }

  doc$(id: string): Observable<T> {
    const path = `${this.defaultPath}/${id}`;
    return this.afs
      .doc(path)
      .snapshotChanges()
      .pipe(
        map(doc => {
          const val = { ...(doc.payload.data() as object) };
          return this.serializer.fromJson(val) as T;
        })
      );
  }

  /**
   * @param  {string} path 'collection' or 'collection/docID'
   * @param  {object} data new data
   *
   * Creates or updates data on a collection or document.
   **/
  private async updateAt(data: T, path?: string): Promise<T> {
    data = this.serializer.toJson(data);
    util.nullifyUndefinedlProps(data);

    if (util.isNullOrUndefined(data['id'])) {
      delete data['id'];
    }

    let usePath = path || this.defaultPath;
    if (data.id !== undefined) {
      usePath = `${usePath}/${data.id}`;
    }
    const segments = usePath.split('/').filter(v => v);
    if (segments.length % 2) {
      // Odd is always a collection

      const docRef = await this.afs.collection<T>(usePath).add(data);
      const dataSnapshot = await docRef.get();
      const model = dataSnapshot.data();
      model.id = docRef.id;
      return Promise.resolve<T>(this.serializer.fromJson(model) as T);
    } else {
      // Even is always document
      return this.afs
        .doc<T>(usePath)
        .set(data, { merge: true })
        .then(() => Promise.resolve<T>(this.serializer.fromJson(data) as T))
        .catch(error => {
          console.error('Error writing document: ', error);
          return Promise.resolve<any>(error);
        });
    }
  }

  private async addList(modelList: T[]): Promise<void> {
    const colRef = this.afs.collection<T>(this.defaultPath);
    const batch = this.afs.firestore.batch();
    modelList.forEach(model => {
      let pmodel = this.serializer.toJson(model);
      util.nullifyUndefinedlProps(pmodel);
      //https://github.com/angular/angularfire2/issues/1974
      //CollectionReference.doc function path should be optional
      const id = pmodel.id || this.afs.createId();
      let docRef = colRef.doc<T>(id).ref;
      pmodel.id = id;
      batch.set(docRef, { ...pmodel });
    });

    return batch.commit();
  }

  //delete collection by query and specified batchsize
  deleteCollection(queryFn?: QueryFn): Observable<any> {
    // const source = this.deleteBatch(ref => ref.where('name', '==', 'test').limit(batchSize));

    const source = this.deleteBatch(queryFn);

    // expand will call deleteBatch recursively until the collection is deleted
    return source.pipe(
      expand(() => this.deleteBatch(queryFn)),
      takeWhile(val => val > 0)
    );
  }

  // Deletes documents as batched transaction
  private deleteBatch(queryFn: QueryFn): Observable<any> {
    const defaultBatchSize = 100;
    const colRef =
      queryFn !== undefined
        ? this.afs.collection(this.defaultPath, queryFn)
        : this.afs.collection(this.defaultPath, ref => ref.orderBy('id').limit(defaultBatchSize));

    return colRef.snapshotChanges().pipe(
      take(1),
      mergeMap(snapshot => {
        // Delete documents in a batch
        const batch = this.afs.firestore.batch();
        snapshot.forEach(doc => {
          batch.delete(doc.payload.doc.ref);
        });
        console.log('Merge delete snapshot length:', snapshot.length);
        return from(batch.commit()).pipe(
          tap(() => console.log('delete snapshot length:', snapshot.length)),
          map(() => snapshot.length)
        );
      })
    );
  }

  // deleteCollection(batchSize: number, queryFn:QueryFn): Observable<any> {
  //   const source = this.deleteBatch(ref => ref.where('name', '==', 'test').limit(batchSize));

  //   // expand will call deleteBatch recursively until the collection is deleted
  //   return source.pipe(
  //     expand(val => this.deleteBatch(ref => ref.where('name', '==', 'test').limit(batchSize))),
  //     takeWhile(val => val > 0)
  //   );
  // }

  // Initial query sets options and defines the Observable
  public initPagination(field?: string, path?: string, opts?: IQueryConfig) {
    this.query = {
      path: path || this.defaultPath,
      field: field || 'created',
      limit: 10,
      reverse: false,
      prepend: false,
      ...opts
    };

    const first = this.afs.collection<T>(this.query.path, ref => {
      return ref.orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc').limit(this.query.limit);
    });

    this.mapAndUpdate(first);

    // Create the observable array for consumption in components
    this.data = this._data.asObservable().pipe(scan((acc, val) => (this.query.prepend ? val.concat(acc) : acc.concat(val))));
  }

  // Retrieves additional data from firestore
  public more(): void {
    const cursor = this.getCursor();

    const more = this.afs.collection<T>(this.query.path, ref => {
      return ref
        .orderBy(this.query.field, this.query.reverse ? 'desc' : 'asc')
        .limit(this.query.limit)
        .startAfter(cursor);
    });
    this.mapAndUpdate(more);
  }

  // Determines the doc snapshot to paginate query
  private getCursor(): any | null {
    const current = this._data.value;
    if (current.length) {
      return this.query.prepend ? current[0].doc : current[current.length - 1].doc;
    }
    return null;
  }

  // Maps the snapshot to usable format the updates source
  private mapAndUpdate(col: AngularFirestoreCollection<T>): Subscription {
    if (this._done.value || this._loading.value) {
      return;
    }

    // loading
    this._loading.next(true);

    // Map snapshot with doc ref (needed for cursor)
    return col
      .snapshotChanges()
      .pipe(
        tap(arr => {
          let values = arr.map(snap => {
            const data: object = snap.payload.doc.data();
            const id = snap.payload.doc.id;
            const doc = snap.payload.doc;
            const val = { id, ...data };
            return this.serializer.fromJson(val) as T;
          });

          // If prepending, reverse array
          values = this.query.prepend ? values.reverse() : values;

          // update source with new values, done loading
          this._data.next(values);
          this._loading.next(false);

          // no more values, mark done
          if (!values.length) {
            this._done.next(true);
          }
        }),
        take(1)
      )
      .subscribe();
  }

  // Reset the page
  public reset(): void {
    this._data.next([]);
    this._done.next(false);
  }
}
