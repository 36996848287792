import { Component, OnInit } from "@angular/core";
import { NavController, PopoverController } from "@ionic/angular";

@Component({
  selector: "app-popover",
  templateUrl: "./popover.page.html",
  styleUrls: ["./popover.page.scss"],
})
export class PopoverPage implements OnInit {
  constructor(
    private navCtr: NavController,
    public popoverController: PopoverController
  ) {}

  ngOnInit() {}

  goHome() {
    this.close();
    this.navCtr.navigateForward("web/home");
  }

  goServices() {
    this.close();
    this.navCtr.navigateForward("web/service");
  }

  goProducts() {
    this.close();
    this.navCtr.navigateForward("web/products");
  }

  goContactUs() {
    this.close();
    this.navCtr.navigateForward("web/contact-us");
  }

  goAboutUs() {
    this.close();
    this.navCtr.navigateForward("web/about-us");
  }

  close() {
    this.popoverController.dismiss();
  }
}
