import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { isObject } from '@koomzo/commonutil';
import { LocaleProp } from '@koomzo/coremodel';

@Pipe({
  name: 'inlinetranslate',
  pure: false
})
export class InlineTranslatePipe implements PipeTransform {
  constructor(private translationService: LanguageService) {}

  // transform(value: any, args?: any): any {
  //   return this.translationService.translate(value)
  // }

  transform(value: any): any {
    return isObject(value) ? value[this.translationService.activeLang] : value;
  }
}
