import { UserSubscribedTags } from '@koomzo/coremodel';
import { FirebaseResourceService } from '@shared/services/firebase-resource.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { IUser, UserSerializer } from '@koomzo/commonmodel';

@Injectable()
export class UsersDbService extends FirebaseResourceService<IUser> {
  defaultPath = 'users';
  constructor(afs: AngularFirestore) {
    super(afs, new UserSerializer());
  }

  updateUserTags(userTags: UserSubscribedTags): Promise<void> {
    const path = `${this.defaultPath}/${userTags.id}`;
    const result = this.afs
      .doc<IUser>(path)
      .update({ subscribedTags: userTags.tags })
      .catch(err => {
        console.error('Error updating document: ', err);
        return Promise.resolve<any>(err);
      });

    return result;
  }
}
