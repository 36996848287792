import { AbstractControl, ValidatorFn } from '@angular/forms';

export class BasicValidators {
  static email(control: AbstractControl) {
    const regEx = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (control.value === null || control.value === undefined) return null;

    const valid = regEx.test(control.value);
    return valid ? null : { email: true };
  }

  /**@method{emailMatcher: confirm email fields match}
   * @param  {AbstractControl} c
   * @returns boolean
   */
  static emailMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    return BasicValidators.fieldMatcher(c, 'email', 'confirmEmail', 'emailMatch', 'emailGroup');
  }

  /**@method{passwordMatcher: confirm password fields match}
   * @param  {AbstractControl} c
   * @returns boolean
   */
  static passwordMatcher(c: AbstractControl): { [key: string]: boolean } | null {
    return BasicValidators.fieldMatcher(c, 'password', 'confirmPassword', 'confirmPassword', 'passwordGroup');
  }

  /**
   * verifies fieldcontrol value is within the designated range
   * @param  {number} min
   * @param  {number} max
   * @returns ValidatorFn
   */
  static ratingRange(min: number, max: number): ValidatorFn {
    return (c: AbstractControl): { [key: string]: boolean } | null => {
      if (c.value !== undefined && (isNaN(c.value) || c.value < min || c.value > max)) {
        return { range: true };
      }
      return null;
    };
  }

  /**
   * generic validator for comparing two fields. two example use cases include
   * email and password
   * @param  {AbstractControl} c
   * @param  {string} fieldName
   * @param  {string} confirmFieldName
   * @param  {string} validatorName
   * @returns boolean
   */
  private static fieldMatcher(
    c: AbstractControl,
    fieldName: string,
    confirmFieldName: string,
    validatorName: string,
    fieldGroupName?: string
  ): { [key: string]: boolean } | null {
    const fieldControl = fieldGroupName !== undefined ? c.get(fieldGroupName + '.' + fieldName) : c.get(fieldName);
    const confirmFieldControl =
      fieldGroupName !== undefined ? c.get(fieldGroupName + '.' + confirmFieldName) : c.get(confirmFieldName);

    const canValidate = fieldControl !== null && confirmFieldControl !== null;
    if (canValidate && (fieldControl.pristine || confirmFieldControl.pristine)) {
      return null;
    }

    if (canValidate && fieldControl.value === confirmFieldControl.value) {
      return null;
    }

    return canValidate ? { validatorName: true } : null;
  }
}
