import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngstack/translate';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Store } from '@ngxs/store';
import { IAppStateModel } from '../store/app.state';

const LNG_KEY = 'SELECTED_LANGUAGE';

/**
 * @classdesc{ this is a wrapper class for third party translation module, this makes
 *  code refactoring easier if decide to swap this third party module} *
 * @see {@link https://www.npmjs.com/package/@ngstack/translate}
 */
@Injectable({ providedIn: 'root' })
export class LanguageService {
  public static readonly DEFAULT_APP_LANGUAGE = 'en';
  private langIds: any = [];
  private supportedLangs: string[] = ['en', 'fr'];
  _activeLang: string;
  get activeLang(): string {
    return this.translate.activeLang;
    //return this._activeLang;
  }

  set activeLang(value: string) {
    this._activeLang = value;
  }

  constructor(private translate: TranslateService, private storage: Storage, private plt: Platform, private store: Store) {
    // this.initAppLang();
  }

  /**
   * single method to set the app language
   * @param  {string} lang?
   * @returns void
   */
  async setInitialAppLanguage(lang?: string): Promise<void> {
    if (lang) {
      this.translate.activeLang = lang;
      this._activeLang = lang;
      this.storage.set(LNG_KEY, lang);
    } else {
      this.initAppLang();
    }
  }

  getLanguages(): any {
    return [
      { text: 'English', value: 'en', img: 'assets/imgs/en.png' },
      { text: 'French', value: 'fr', img: 'assets/imgs/fr.png' }
    ];
  }

  setLanguage(lang: string): void {
    this.translate.activeLang = lang;
    this.translate.use(lang);
    this._activeLang = lang;
    this.storage.set(LNG_KEY, lang);
  }

  /**
   * init user's lang from the user's  browser Lang. If not amongst list of
   * support langs, default to 'en'
   * @returns void
   */
  private async initAppLang(): Promise<void> {
    // const lang = await this.storage.get(LNG_KEY);
    const appState = this.store.selectSnapshot<IAppStateModel>(state => state.app);
    const lang = appState?.appLang;
    this._activeLang = LanguageService.DEFAULT_APP_LANGUAGE;
    const browserLang = this.getBrowserLang();
    if (lang !== undefined) {
      this.translate.use(lang as string);
      this._activeLang = lang;
    } else if (browserLang !== undefined && this.supportedLangs.includes(browserLang)) {
      this.translate.use(browserLang);
      this._activeLang = browserLang;
    } else {
      this._activeLang = LanguageService.DEFAULT_APP_LANGUAGE;
      this.translate.use(LanguageService.DEFAULT_APP_LANGUAGE);
    }
    this.storage.set(LNG_KEY, this._activeLang);
  }

  /**@copyright : copied from ngx-translate git project
   * Returns the language code name from the browser, e.g. "de"
   * @returns string
   */
  public getBrowserLang(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }

    let browserLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
    // browserLang =
    //   browserLang || window.navigator.language || window.navigator.browserLanguage || window.navigator.userLanguage;
    browserLang = browserLang || window.navigator.language;

    if (browserLang.indexOf('-') !== -1) {
      browserLang = browserLang.split('-')[0];
    }

    if (browserLang.indexOf('_') !== -1) {
      browserLang = browserLang.split('_')[0];
    }

    return browserLang;
  }

  /**@copyright : copied from ngx-translate git project
   * Returns the culture language code name from the browser, e.g. "de-DE"
   * @returns string
   */
  public getBrowserCultureLang(): string {
    if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
      return undefined;
    }
    let browserCultureLang: any = window.navigator.languages ? window.navigator.languages[0] : null;
    browserCultureLang = browserCultureLang || window.navigator.language;

    return browserCultureLang;
  }

  /**@method{get: wrapper function}
   * @param  {string} key
   * @returns string
   */
  public get(key: string): string {
    return this.translate.get(key);
  }

  public getErrorMessageByKey(key: string): string {
    const pKey = 'errorMsgString.' + key;
    return this.translate.get(pKey);
  }
}
