import { Input, Component, OnInit } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { PopoverPage } from "@pages/popover/popover.page";

@Component({
  selector: "site-header",
  templateUrl: "./site-header.component.html",
  styleUrls: ["./site-header.component.scss"],
})
export class SiteHeaderComponent implements OnInit {
  constructor(public popoverController: PopoverController) {}

  async presentPopover(ev: any) {
    console.log("presentPopover");
    console.log(ev.value);
    const popover = await this.popoverController.create({
      component: PopoverPage,
      cssClass: "my-custom-class",
      event: ev,
      translucent: true,
    });
    return await popover.present();
  }

  ngOnInit(): void {}
}
