// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  googlePlusWebClientID:
    "652379840626-lursuu217op31ceebhesc3qe9al39c5o.apps.googleusercontent.com",
  firebase: {
    apiKey: "AIzaSyC8_ENSY6XTo2LiM7RpTnNIzWp9DPmKvNM",
    authDomain: "koomzo.firebaseapp.com",
    databaseURL: "https://koomzo.firebaseio.com",
    projectId: "koomzo",
    storageBucket: "koomzo.appspot.com",
    messagingSenderId: "645634533235",
    appId: "1:645634533235:web:7d8730d3127c8a96428706",
    measurementId: "G-92GZLKZCT5",
  },
  googleMapsKey: "AIzaSyB33GhqCI9VJbg0deZVC_sz_4ScIbzqKOQ",
  log: true,
  flags: {
    useNewHeader: true,
  },
  logging: {
    logLevels: [
      {
        loggerName: "root",
        logLevel: "DEBUG",
      },
      {
        loggerName: "Ionic.Logging",
        logLevel: "OFF",
      },
    ],
    localStorageAppender: {
      localStorageKey: "ionic.logging.sample",
      threshold: "INFO",
      maxMessages: 250,
    },
  },
  rollbarConfig: {
    accessToken: "09b75e1948cc456a8fd2afd1d1f0ce2c",
    captureUncaught: true,
    captureUnhandledRejections: true,
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
