export function stringIsInArray(arr: any[], str: string) {
  const str1 = str.toLowerCase();
  if (arr) {
    const tmp = arr.join('-').toLowerCase();
    const lcArr = tmp.split('-');
    return lcArr.indexOf(str1) > -1;
  } else {
    return false;
  }
}
