import { ISerializer, IResource } from '@koomzo/coremodel';

/** platform wide model to track properties about the platform */
export interface IPlatformSetup extends IResource {
  name: string;
  isSeeded: boolean;
}

export class PlatformSetup implements IPlatformSetup {
  id: string;
  name: string;
  isSeeded: boolean;
  constructor(value: { name: string; isSeeded?: boolean; id?: string }) {
    this.name = value.name;
    this.id = value.id;
    this.isSeeded = value.isSeeded;
  }
}

export class PlatformSetupSerializer implements ISerializer {
  fromJson(json: any): PlatformSetup {
    const platformSetup = new PlatformSetup({ ...json });
    return platformSetup;
  }

  toJson(jmodel: PlatformSetup): any {
    const model = new PlatformSetup({ ...jmodel });
    // model.execute();
    return { ...model };
  }
}
