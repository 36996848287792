// import * as PouchDb from 'pouchdb-browser';
// import PouchDB from 'pouchdb-browser';
import { Injectable } from '@angular/core';

@Injectable()
export class OfflineDbManager {
  _dbName: 'koomzo_db';
  get dbName() {
    return this._dbName;
  }
  db: any;

  // private pouchDatadatabaseExists(dbname): Promise<boolean> {
  //   return new Promise((resolve, reject) => {
  //     const req = indexedDB.open(`_pouch_${dbname}`);
  //     req.onsuccess = function() {
  //       resolve(true);
  //     };
  //     req.onupgradeneeded = function() {
  //       resolve(false);
  //     };
  //   });
  // }

  // getDb(): any {
  //   return this.db;
  // }

  // async createDb(dbname: string): Promise<any> {
  //   let db: PouchDB.Database<{}>;
  //   const exist = await this.pouchDatadatabaseExists(this.dbName);
  //   if (!exist) {
  //     db = new PouchDb(dbname, { auto_compaction: true });
  //   }
  //   return db;
  // }

  // async destroyDb() {
  //   try {
  //     await this.db.destroy();
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }

  // /** https://pouchdb.com/api.html#debug_mode*/
  // setDebugMode(isDebug: boolean = true): void {
  //   isDebug ? PouchDb.debug.enable('*') : PouchDb.debug.disable();
  // }
}
