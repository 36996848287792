<ion-header>
  <ion-row class="ion-align-items-center ion-justify-content-center">
    <ion-col sizeLg="8">
      <ion-toolbar>
        <ion-buttons slot="start">
          <img src="assets/imgs/logo.svg" alt="" />
        </ion-buttons>
        <!--    <ion-title >
                    <ion-searchbar placeholder="Search on savelist" inputmode="decimal" type="decimal" [debounce]="250" showCancelButton="never"></ion-searchbar>
                </ion-title> -->
        <ion-buttons slot="end" id="dot" (click)="presentPopover($event)">
          <ion-button fill="clear">
            <ion-icon
              name="ellipsis-horizontal-outline"
              slot="icon-only"
            ></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="tbtns">
          <ion-button id="login">
            LOG IN
          </ion-button>
        </ion-buttons>
        <ion-buttons slot="end" class="tbtns">
          <ion-button id="signup">
            SIGNUP
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-col>
  </ion-row>
</ion-header>
