import { isNullOrUndefined } from 'util';

/**
 *
 * @param str
 */
export function parseStringToUrl(str: string) {
  return !isNullOrUndefined(str)
    ? str
        .replace(/(?!\w|\s)./g, '')
        .replace(/\s+/g, ' ')
        .replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2')
        .replace(/[^A-Z0-9]+/gi, '')
        .toLowerCase()
    : null; // remove space characters to concte
}
