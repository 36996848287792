import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';

/**https://stackoverflow.com/questions/54052954/ionic-4-with-angular7-issue-creating-loader-service-async-await  */
@Injectable()
export class LoaderService {
  loader: HTMLIonLoadingElement;

  constructor(public loadingController: LoadingController) {}

  async showLoader(message?: string, duration?: number) {
    if (this.loader) {
      this.loader.present();
    } else {
      this.loader = await this.loadingController.create({
        spinner: 'crescent',
        message: message,
        duration: duration || 2000
      });
      await this.loader.present();
    }
  }

  hideLoader() {
    if (this.loader) this.loader.dismiss();
    this.loader = null;
  }
}
