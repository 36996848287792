import { Injectable } from '@angular/core';
import { AlertController} from '@ionic/angular';


@Injectable()
export class AlertService {

    constructor(public alertCtrl: AlertController) {}

    async presentAlert(header: string, message: string) {

        const alert = await this.alertCtrl.create({
            header,
            subHeader: message,
            buttons: [{
                         text: 'ok'
                    }],
        });
        return alert.present();
    }



    presentErrorAlert(message: string) {
        return this.presentAlert('An error has occured', message);
    }

    presentAlertWithCallback(header: string, message: string): Promise<boolean> {

        return new Promise(async (resolve, reject) => {

            const confirm = await this.alertCtrl.create({
                            header,
                            subHeader: message,
                            buttons: [
                                        {
                                            text: 'cancel',
                                            role: 'cancel',
                                            handler: () => {
                                                confirm.dismiss().then(() => resolve(false));
                                                return false;
                                            }
                                        },
                                        {
                                            text: 'Yes',
                                            role: 'accept',
                                            handler: () => {
                                                confirm.dismiss().then(() => resolve(true));
                                                return false;
                                            }
                                        }
                                    ]

                        });

            return confirm.present();

        });

    }







}