import { FirebaseResourceService } from '@shared/services/firebase-resource.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { Injectable } from '@angular/core';
import { IDataDictionary, DataDictionarySerializer } from '@koomzo/coremodel';

@Injectable()
export class DataDictionaryDbService extends FirebaseResourceService<IDataDictionary> {
  defaultPath = 'datadictionary';
  constructor(afs: AngularFirestore) {
    super(afs, new DataDictionarySerializer());
  }
}
