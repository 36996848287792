import { IResource, IFileContent, IAuditData, ISerializer, ILocaleProp } from '@koomzo/coremodel';
import * as util from '@koomzo/commonutil';

export interface ITenantViewModel extends IResource {
  name: string;
  code: string;
  webAddress?: string;
  logo?: IFileContent;
  language: string;
  mobile: string;
  password: string;
  email: string;
  lastname: string;
  givennames: string;
  isMaster?: boolean;
  tenantType: string;
  authorStatus?: string; // pending | active | inactive | archived
  // tenantSites?: ITenantSite[];
}

export class TenantViewModel implements ITenantViewModel {
  name: string;
  code: string;
  webAddress?: string;
  logo?: IFileContent;
  language: string;
  mobile: string;
  password: string;
  email: string;
  lastname: string;
  givennames: string;
  isMaster?: boolean;
  tenantType: string;
  authorStatus: string;
  id?: string;
  displayOrder?: number;
  //tenantSites?: ITenantSite[];

  constructor(value: {
    name: string;
    code?: string;
    webAddress?: string;
    logo?: IFileContent;
    language: string;
    mobile: string;
    password: string;
    email: string;
    lastname: string;
    givennames: string;
    isMaster?: boolean;
    tenantType: string;
    authorStatus: string;
    id?: string;
    displayOrder?: number;
  }) {
    this.name = value.name;
    this.id = value.id;
    this.code = value.code;
    this.email = value.email;
    this.mobile = value.mobile;
    this.password = value.password;
    this.language = value.language;
    this.isMaster = util.isBoolean(value.isMaster) ? value.isMaster : false;
    this.tenantType = value.tenantType;
    this.logo = value.logo;
    this.authorStatus = !util.isNullOrUndefined(this.authorStatus) ? this.authorStatus : 'pending';
  }
}

export interface ITenant extends IResource, IAuditData {
  name: string;
  code: string;
  webAddress?: string;
  logo?: IFileContent;
  language: string;
  email: string;
  password: string;
  isMaster: boolean;
  authorStatus: string; // pending | active | inactive | archived
  tenantType: string;
  TenantSection?: string;
  tenantSites: ITenantSite[];
  isVerified?: boolean;
}

export interface ITenantSite {
  id: string; // site1, site2, site3, ...
  name: string;
  // code: string;
  // groupCode: string;
  language: string;
  index?: number;
  //tenantSiteId: string;

  /** */
  tenantType: string;

  //24hr format: 09:00
  openTIme?: string;
  //24hr format: 16:00 etc
  closeTIme?: string;

  //ex: parking not available, accept orange money,
  facilities?: ILocaleProp[];

  /**a provider type can be made of different sections ex
   * example a school tenanttype can be made of kinderGardern, primarySchool, secondarySchool, highSchool
   * for other cases, tenantType is not divided into sections hence nullable*/
  tenantSection?: string;
}

export class TenantSite implements ITenantSite {
  public id: string;
  public name: string;
  // public code: string;
  // public groupCode: string;
  public language: string;
  public tenantType: string;
  public tenantSection?: string;
  public index?: number;
  public openTIme?: string;
  public closeTIme?: string;
  public facilities?: ILocaleProp[];

  constructor(value: {
    id: string;
    name: string;
    // code: string;
    // groupCode: string;
    language: string;
    tenantType: string;
    tenantSection?: string;
    index?: number;
    openTIme?: string;
    closeTIme?: string;
    facilities?: ILocaleProp[];
  }) {
    this.id = value.id;
    this.name = value.name;
    // this.code = value.code;
    // this.groupCode = value.groupCode;
    this.language = value.language;
    this.tenantType = value.tenantType;
    this.tenantSection = value.tenantSection;
    this.index = value.index;
    this.openTIme = value.openTIme;
    this.closeTIme = value.closeTIme;
    this.facilities = value.facilities;
  }
  // validation, and business logic
}

export class TenantSiteSerializer implements ISerializer {
  fromJson(json: any): TenantSite {
    const model = new TenantSite({ ...json });
    return model;
  }

  toJson(model: TenantSite): any {
    //model.execute();
    return { ...model };
  }
}

/**Tenant includes common attributes and a collection of tenant sites(tenant types/geo sites) allowing tenant to
 * has its own sub multi-tenant structure  */
export class Tenant implements ITenant {
  uid?: string;
  public id?: string;
  public name: string;
  public code: string;
  public webAddress?: string;
  public logo?: IFileContent;
  public language: string;
  public tenantType: string;
  public tenantSection?: string;
  public isVerified?: boolean;

  /** these properties are require only during tenant account creation/signup, to associate a
   * admin user account with the new tenant account
   * */
  public email: string;
  public mobile?: string;
  public password: string;
  public isMaster: boolean;
  //public tenantType: string;

  public tenantSites: ITenantSite[];
  public authorStatus: string;
  public createdAt?: Date;
  public updatedAt?: Date;

  constructor(value: {
    id?: string;
    name: string;
    code?: string;
    webAddress?: string;
    language: string;
    email: string;
    mobile?: string;
    password: string;
    isMaster?: boolean;
    tenantType: string;
    tenantSection?: string;
    tenantSites?: ITenantSite[];
    isVerified?: boolean;
    authorStatus: string;
    uid?: string;
    logo?: IFileContent;
    createdAt?: Date;
  }) {
    this.id = value.id;
    this.name = value.name;
    this.webAddress = value.webAddress;
    this.language = value.language;
    this.email = value.email;
    this.mobile = value.mobile;
    this.password = value.password;
    this.isMaster = value.isMaster;
    this.tenantType = value.tenantType;
    this.tenantSection = value.tenantSection;
    this.isVerified = value.isVerified;
    this.logo = value.logo;
    this.createdAt = value.createdAt;

    this.isMaster = util.isBoolean(value.isMaster) ? value.isMaster : false;
    this.authorStatus = !util.isNullOrUndefined(this.authorStatus) ? this.authorStatus : 'pending';
    this.tenantSites = value.tenantSites;
    // if (value.tenantSites) {
    //   this.tenantSites = value.tenantSites;
    // }
    this.authorStatus = value.authorStatus;
    this.updatedAt = new Date();
  }

  execute(): void {
    if (util.isNullOrUndefined(this.id)) {
      //on new tenant create
      this.code = util.parseStringToUrl(this.name);
      this.createdAt = new Date();
      // on tenant create

      if (util.isNullOrUndefined(this.tenantSites)) {
        this.tenantSites = [];
        const siteId = 'site1';
        const site = {
          id: siteId,
          code: util.parseStringToUrl(`${this.id}_${this.name}_${siteId}`),
          groupCode: util.parseStringToUrl(`${this.name}`),
          name: this.name, //can be be changed late
          language: this.language,
          tenantType: this.tenantType,
          tenantSection: this.tenantSection,
          index: 1
        };
        const tenantSite = new TenantSite({ ...site });
        this.tenantSites.push(tenantSite);
      }
    } else {
      this.updatedAt = new Date();

      //this.tenantSites.forEach(s => {});

      for (let i = 0; i < this.tenantSites.length; i++) {
        this.tenantSites[i].id = `site${i}`;
      }
    }

    util.nullifyUndefinedlProps(this);
    return;
  }

  //public static CreateTenantFromView(model: ITenantViewModel) {}
  // validation, and business logic
}

export class TenantSerializer implements ISerializer {
  fromJson(json: any): ITenant {
    const model = new Tenant({ ...json });
    model.execute();
    return model;
  }

  toJson(model: ITenant): any {
    //const pModel = new Tenant({ ...model });
    model.execute();
    const tenantSiteSerializer = new TenantSiteSerializer();
    model.tenantSites = model.tenantSites.map(s => tenantSiteSerializer.toJson(s));
    const jModel = { ...model };
    return jModel;
  }
}

export interface ITenantSection extends IResource {
  name: string;
  code: string;
  tenantType: string;
  description: string;
}

export class TenantSection implements ITenantSection {
  name: string;
  code: string;
  tenantType: string;
  description: string;
  id?: string;
  constructor(value: { id?: string; name: string; code: string; description: string; tenantType: string }) {
    this.id = value.id;
    this.name = value.name;
    this.code = value.code;
    this.description = value.description;
    this.tenantType = value.tenantType;
  }
  execute?: () => void;
}

export class TenantSectionSerializer implements ISerializer {
  fromJson(json: any): TenantSection {
    const model = new TenantSection({ ...json });
    return model;
  }

  toJson(model: TenantSection): any {
    //model.execute();
    return { ...model };
  }
}

export interface ITenantType extends IResource {
  name: string;
  code: string;
  description: string;
  sections?: ITenantSection[];
}

export class TenantType implements ITenantType {
  public id: string;
  public name: string;
  public code: string;
  public description: string;
  public sections?: ITenantSection[];

  constructor(value: { id?: string; name: string; code: string; description: string; sections?: ITenantSection[] }) {
    this.id = value.id;
    this.name = value.name;
    this.code = value.code;
    this.description = value.description;
    this.sections = value.sections;
  }

  // validation, and business logic
}

export class TenantTypeSerializer implements ISerializer {
  fromJson(json: any): TenantType {
    const model = new TenantType({ ...json });
    return model;
  }

  toJson(model: TenantType): any {
    // model.execute();
    const jmodel = { ...model };
    if (jmodel.sections !== undefined) {
      const tenantSectionSerializer = new TenantSectionSerializer();
      const jdata: any[] = [];
      jmodel.sections.forEach(dItem => {
        const objItem = tenantSectionSerializer.toJson(dItem);
        util.nullifyUndefinedlProps(objItem);
        jdata.push(objItem);
      });
      jmodel.sections = jdata;
    }
    return jmodel;
  }
}
export interface IUniqueTenantname extends IResource {
  tenantname: string;
}

export class UniqueTenantname implements IUniqueTenantname {
  public tenantname: string;
  public id: string;

  constructor(value: { tenantname: string; id: string }) {
    this.id = value.id;
    this.tenantname = value.tenantname;
  }
}

export class UniqueTenantnameSerializer implements ISerializer {
  fromJson(json: any): UniqueTenantname {
    const model = new UniqueTenantname({ ...json });
    return model;
  }

  toJson(model: UniqueTenantname): any {
    return { ...model };
  }
}
