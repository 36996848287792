import { Directive, Input, OnInit, OnDestroy, DoCheck, Inject, HostBinding, forwardRef } from '@angular/core';

import { Subscription, Subject, Observable, combineLatest } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

import { NgxErrorsDirective } from './ngxerrors.directive';

import { ErrorOptions, IErrorDetails } from './ngxerrors';

import { toArray } from './utils/toArray';

@Directive({
  selector: '[ngxError]'
})
export class NgxErrorDirective implements OnInit, OnDestroy, DoCheck {
  @Input()
  set ngxError(value: ErrorOptions) {
    this.errorNames = toArray(value);
  }

  @Input()
  set when(value: ErrorOptions) {
    this.rules = toArray(value);
  }

  @HostBinding('hidden')
  hidden: boolean = true;

  rules: string[] = [];

  errorNames: string[] = [];

  subscription: Subscription;

  _states: Subject<string[]>;

  states: Observable<string[]>;

  constructor(@Inject(forwardRef(() => NgxErrorsDirective)) private ngxErrors: NgxErrorsDirective) {}

  ngOnInit() {
    this._states = new Subject<string[]>();
    this.states = this._states.asObservable().pipe(distinctUntilChanged());

    const errors = this.ngxErrors.subject.pipe(
      filter(Boolean),
      filter((obj: IErrorDetails) => !!~this.errorNames.indexOf(obj.errorName))
    );

    const states = this.states.pipe(map(state => this.rules.every(rule => !!~state.indexOf(rule))));

    this.subscription = combineLatest(states, errors).subscribe(([statesVal, errorsVal]) => {
      this.hidden = !(statesVal && errorsVal.control.hasError(errorsVal.errorName));
    });
  }

  ngDoCheck() {
    this._states.next(this.rules.filter(rule => (this.ngxErrors.control as any)[rule]));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }
}
