<ion-content>
  <ion-grid fixed>
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size="6" class="ion-text-center">
        <ion-button id="login">
          LOG IN
        </ion-button>
      </ion-col>
      <ion-col size="6" class="ion-text-center">
        <ion-button id="signup">
          SIGNUP
        </ion-button>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-list>
          <ion-item detail (click)="goHome()">
            <ion-label>
              Home
            </ion-label>
          </ion-item>
          <ion-item detail (click)="goServices()">
            <ion-label>
              Services
            </ion-label>
          </ion-item>
          <ion-item detail (click)="goProducts()">
            <ion-label>
              Products
            </ion-label>
          </ion-item>
          <ion-item detail (click)="goContactUs()">
            <ion-label>
              Contact us
            </ion-label>
          </ion-item>
          <ion-item detail (click)="goAboutUs()">
            <ion-label>
              About us
            </ion-label>
          </ion-item>
        </ion-list>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
