import { AlertService } from "./util/alert.service";
import { ToastService } from "./util/toast.service";
import { DataDictionaryDbService } from "./data-dictionary-db.service";
import { FirebaseStorageService } from "./firebase-storage.service";
// import { TranslationService } from './translation.service';
import { UsersDbService } from "./users-db.service";
import { FirebaseDbService } from "./db/firebase-db.service";
import { OfflineDbManager } from "./db/offline-db-manager.service";
import { OfflineDbService } from "./db/offline-db.service";
import { EnvServiceProvider } from "./evn/env.service.provider";
import { JsonConfigService } from "./json-config.service";
import { LanguageService } from "./language.service";
import { AppInjectorService } from "./app-injector.service";
import { LoaderService } from "./util/loader.service";
import { EnvService } from "./evn/env.service";
import { InternalizationService } from "./internalization.service";
import { PlatformSetupDbService } from "./platform-setup-db.service";
//import { FirebaseResourceService } from './firebase-resource.service';

export const services: any[] = [
  AppInjectorService,
  AlertService,
  ToastService,
  // ThemeService,
  DataDictionaryDbService,
  //FirebaseResourceService,
  FirebaseStorageService,
  UsersDbService,
  LoaderService,
  //TranslationService,
  LanguageService,
  //pushnotifcation
  //env
  EnvServiceProvider,
  EnvService,
  //config
  JsonConfigService,
  //dbs
  FirebaseDbService,
  OfflineDbManager,
  OfflineDbService,
  //utils
  InternalizationService,
  PlatformSetupDbService,
];

export * from "./app-injector.service";
//export * from './theme.service';
export * from "./util/alert.service";
export * from "./util/toast.service";
export * from "./util/loader.service";

export * from "./data-dictionary-db.service";
export * from "./firebase-storage.service";
// export * from './translation.service';

export * from "./language.service";
export * from "./users-db.service";

export * from "./evn/env.service.provider";
export * from "./evn/env.service";
export * from "./json-config.service";
//export* from './firebase-resource.service';

export * from "./internalization.service";
export * from "./platform-setup-db.service";
