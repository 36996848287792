import { NgModule, NO_ERRORS_SCHEMA, ModuleWithProviders } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';

// components
import * as fromComponents from './components/index';

// directives
import * as fromDirectives from './directives/index';

// pipes
import * as fromPipes from './pipes/index';

// services
import * as fromServices from './services/index';

// validators
import * as fromValidators from './validators/index';
import { NgxsModule } from '@ngxs/store';
import { TranslateModule } from '@ngstack/translate';
import { AppState } from './store/app.state';

// guards
//import * as fromGuards from './guards';

@NgModule({
  imports: [CommonModule, ReactiveFormsModule, FormsModule, IonicModule, NgxsModule.forFeature([AppState]), TranslateModule.forChild()],
  declarations: [...fromComponents.components, ...fromDirectives.directives, ...fromPipes.pipes],
  // providers: [...fromServices.services, ...fromValidators.validators, fromPipes.pipes],
  exports: [
    ...fromComponents.components,
    ...fromDirectives.directives,
    ...fromPipes.pipes,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    IonicModule,
    NgxsModule,
    TranslateModule
  ],
  schemas: [NO_ERRORS_SCHEMA]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [...fromServices.services, ...fromValidators.validators, fromPipes.pipes]
    };
  }
}
