import { Observable } from 'rxjs';
import { IResource } from '@koomzo/coremodel';
import { Injectable } from '@angular/core';

@Injectable()
export abstract class BaseDbService<T extends IResource> {
  abstract get(path?: string, predicate?: any): Observable<T[]>;
  abstract getById(id: string): Observable<T>;
  abstract addOrUpdate(obj: T): Promise<T>;
  abstract add(obj: T[]): Promise<void>;
  abstract delete(id: string): Promise<void>;
  abstract deleteList(queryFn: any): Observable<any>;
}
