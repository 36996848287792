import { IResource } from './resource.interface';
import { ISerializer } from './serializer.model';
import { ILocaleProp, LocaleProp } from './locale-prop.model';
// export const EventScheduleDataDictionary = new Map<string,Map<string,ILocaleProp>>
export enum RepetitionType {
  Default = 'Default',
  OneTime = 'OneTime',
  Daily = 'Daily',
  WeekDays = 'WeekDays',
  Mon = 'Mon',
  Tue = 'Tue',
  Wed = 'Wed',
  Thur = 'Thur',
  Fri = 'Fri',
  Sat = 'Sat',
  Sun = 'Sun'
}
export const RepetitionTypeMap = new Map<string, ILocaleProp>();
RepetitionTypeMap.set(RepetitionType.Default, new LocaleProp({ en: 'OneTime', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.OneTime, new LocaleProp({ en: 'Daily', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Daily, new LocaleProp({ en: 'Week days', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.WeekDays, new LocaleProp({ en: 'Mondays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Mon, new LocaleProp({ en: 'Tuesdays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Tue, new LocaleProp({ en: 'Wednesdays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Wed, new LocaleProp({ en: 'Thursdays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Thur, new LocaleProp({ en: 'Fridays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Fri, new LocaleProp({ en: 'Saturdays', fr: 'SMS' }));
RepetitionTypeMap.set(RepetitionType.Sat, new LocaleProp({ en: 'Sundays', fr: 'SMS' }));

// export enum ReminderType {
//   OnTime = 'OnTime',
//   OneMinute = 'OneMinute',
//   FiveMinutes = 'FiveMinutes',
//   TenMinutes = 'TenMinutes',
//   FifthteenMinutes = 'FifthteenMinutes',
//   TwentyMinutes = 'TwentyMinutes',
//   TwentyFiveMinutes = 'TwentyFiveMinutes',
//   ThirtyMinutes = 'ThirtyMinutes',
//   FortyFiveMinutes = 'FortyFiveMinutes',
//   OneHour = 'OneHour',
//   TwoHours = 'TwoHours',
//   ThreeHours = 'ThreeHours',
//   SixHours = 'SixHours',
//   TwelveHours = 'TwelveHours',
//   TwentyFourHours = 'TwentyfourHour',
//   TwoDays = 'TwoDays',
//   ThreeDays = 'ThreeDays',
//   oneWeek = 'oneWeek',
//   TwoWeeks = 'TwoWeeks'
// }

export enum ReminderType {
  OnTime = 0,
  OneMinute = 1,
  FiveMinutes = 5,
  TenMinutes = 10,
  FifthteenMinutes = 15,
  TwentyMinutes = 20,
  TwentyFiveMinutes = 25,
  ThirtyMinutes = 30,
  FortyFiveMinutes = 45,
  OneHour = 60,
  TwoHours = 120,
  ThreeHours = 180,
  SixHours = 360,
  TwelveHours = 720,
  TwentyFourHours = 1440,
  TwoDays = 2880,
  ThreeDays = 4320,
  oneWeek = 10080,
  TwoWeeks = 20160
}
export const ReminderTypeMap = new Map<number, ILocaleProp>();
ReminderTypeMap.set(ReminderType.OnTime, new LocaleProp({ en: 'Now', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.OneMinute, new LocaleProp({ en: 'One minute', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.FiveMinutes, new LocaleProp({ en: 'Five Minutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TenMinutes, new LocaleProp({ en: 'Ten Minutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.FifthteenMinutes, new LocaleProp({ en: 'FifthteenMinutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwentyMinutes, new LocaleProp({ en: 'TwentyMinutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwentyFiveMinutes, new LocaleProp({ en: 'TwentyFiveMinutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.ThirtyMinutes, new LocaleProp({ en: 'ThirtyMinutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.FortyFiveMinutes, new LocaleProp({ en: 'FortyFiveMinutes', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.OneHour, new LocaleProp({ en: 'OneHour', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwoHours, new LocaleProp({ en: 'Two Hours', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.ThreeHours, new LocaleProp({ en: 'Three Hours', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.SixHours, new LocaleProp({ en: 'Six Hours', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwelveHours, new LocaleProp({ en: 'Twelve Hours', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwentyFourHours, new LocaleProp({ en: 'TwentyFour Hours', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwoDays, new LocaleProp({ en: 'Two Days', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.ThreeDays, new LocaleProp({ en: 'Three Days', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.oneWeek, new LocaleProp({ en: 'One Week', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwoWeeks, new LocaleProp({ en: 'Two Weeks', fr: 'SMS' }));
ReminderTypeMap.set(ReminderType.TwoWeeks, new LocaleProp({ en: 'ThreeDays', fr: 'SMS' }));

export enum NotifyByType {
  Sms = 'Sms',
  Email = 'Email',
  PushNotification = 'PushNotification',
  LocalNotification = 'LocalNotification'
}

export const NotifyByTypeMap = new Map<string, ILocaleProp>();
NotifyByTypeMap.set(NotifyByType.Sms, new LocaleProp({ en: 'SMS', fr: 'SMS' }));
NotifyByTypeMap.set(NotifyByType.Email, new LocaleProp({ en: 'Email', fr: 'SMS' }));
NotifyByTypeMap.set(NotifyByType.PushNotification, new LocaleProp({ en: 'Push notification', fr: 'SMS' }));
NotifyByTypeMap.set(NotifyByType.LocalNotification, new LocaleProp({ en: 'Local notification', fr: 'SMS' }));

export interface IEventSchedule extends IResource {
  startDate: Date;
  endDate?: Date;
  startTime: string;
  endTime?: string;
  repetition: string;
  reminder: string;
  notifiyBy: string;
}

export class EventSchedule implements IEventSchedule {
  startDate: Date;
  endDate?: Date;
  startTime: string;
  endTime?: string;
  repetition: string;
  reminder: string;
  notifiyBy: string;
  id?: string;
  createdAt?: Date;
  UpdatedAt?: Date;
  displayOrder?: number;
  selected?: boolean;
  execute?: () => void;

  constructor(value: {
    startDate: Date;
    endDate?: Date;
    startTime: string;
    endTime?: string;
    repetition: string;
    reminder: string;
    notifiyBy: string;
  }) {
    this.startDate = value.startDate;
    this.endDate = value.endDate;
    this.startTime = value.startTime;
    this.endTime = value.endTime;
    this.repetition = value.repetition;
    this.reminder = value.reminder;
    this.notifiyBy = value.notifiyBy;
  }
}

export class EventScheduleSerializer implements ISerializer {
  fromJson(json: any): IEventSchedule {
    const model = new EventSchedule({ ...json });
    model.execute();
    return model;
  }

  toJson(model: IEventSchedule): any {
    model.execute();
    const jModel = { ...model };
    return jModel;
  }
}

export function getOptionsFromEnumMap(map: Map<string | number, ILocaleProp>): any[] {
  const result: any[] = [];
  map.forEach((value: ILocaleProp, key: string | number) => {
    result.push({ name: value.en, code: key, selected: false });
  });
  //map.forEach( (key,value)=> result.push({label: map.get(key).en, value:key, selected:false}));
  return result;
}
