import { NgxErrorsDirective } from "./ngx-errors/ngxerrors.directive";
import { NgxErrorDirective } from "./ngx-errors/ngxerror.directive";
import { StyleCellDirective } from "./style-cell.directive";
import { ListItemTemplateDirective } from "./list-item-template.directive";

export const directives: any[] = [
  NgxErrorsDirective,
  NgxErrorDirective,
  StyleCellDirective,
  ListItemTemplateDirective,
];

export * from "./ngx-errors/ngxerrors.directive";
export * from "./ngx-errors/ngxerror.directive";
export * from "./style-cell.directive";
export * from "./list-item-template.directive";
