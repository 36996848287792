<ion-footer mode="md">
  <div class="container">
    <ion-row class="ion-align-items-center ion-justify-content-center">
      <ion-col size="12" class="flex al-center jc-center ion-text-center">
        <ion-button (click)="onClick()" fill="clear" mode="md">
          Terms
        </ion-button>
        |
        <ion-button (click)="onClick()" fill="clear" mode="md">
          Privacy
        </ion-button>
      </ion-col>
    </ion-row>
  </div>
</ion-footer>
