import { Component, OnInit } from "@angular/core";

@Component({
  selector: "site-footer",
  templateUrl: "./site-footer.component.html",
  styles: ["./site-footer.component.scss"],
})
export class SiteFooterComponent implements OnInit {
  constructor() {}

  ngOnInit() {}

  onClick(): void {
    console.log("coming soon...");
  }
}
