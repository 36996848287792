import { Resolve } from '@angular/router';
import { IResource } from '@koomzo/coremodel';
import { Injectable } from '@angular/core';
import { OfflineDbManager } from './offline-db-manager.service';

@Injectable()
export abstract class OfflineDbService<T extends IResource> {
  db: any;

  constructor(private _dbManager: OfflineDbManager) {
    // this.db = this._dbManager.getDb();
  }

  async add(model: T): Promise<void> {
    try {
      const obj = Object.assign({}, model);
      const res = await this.db.post(obj);
    } catch (err) {
      console.log(err);
    }
  }

  async addList(models: T[]): Promise<boolean> {
    try {
      const objs = models.map(model => Object.assign({}, model));
      const res = await this.db.bulkDocs(objs);
      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  async getById(id: string): Promise<T> {
    try {
      const res = await this.db.get(id);
      return Promise.resolve<T>(res as T);
    } catch (err) {
      console.log(err);
    }
  }

  /**get document by indexed field */
  async getByField(name: string): Promise<T> {
    const query = await this.db.get(name);
    return Promise.resolve<T>(query as T);
  }

  //   async getList(): Promise<T[]> {

  //     try {
  //         const result = await this.db.allDocs({
  //           include_docs: true,
  //           attachments: true
  //         });
  //       } catch (err) {
  //         console.log(err);
  //       }

  //   }

  async update(model: T): Promise<T> {
    try {
      const obj = Object.assign({}, model);
      const res = await this.db.put(obj);
      return Promise.resolve<T>(res as T);
    } catch (err) {
      console.log(err);
    }
  }

  async updateList(models: T[]): Promise<boolean> {
    try {
      const objs = models.map(model => Object.assign({}, model));
      const res = await this.db.bulkDocs(objs);
      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }

  async delete(id: string): Promise<boolean> {
    try {
      const doc = await this.db.get(id);
      await this.db.remove(doc);
      return Promise.resolve(true);
    } catch (err) {
      console.log(err);
      return Promise.resolve(false);
    }
  }
}
