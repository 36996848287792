import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';

@Injectable()
export class ToastService {
  // toast: Toast;
  constructor(public toastCtrl: ToastController) {}

  async create(message: string, ok: boolean = false, duration: number = 2000, cssClass: string = '') {
    const toast = await this.toastCtrl.create({
      cssClass: cssClass,
      message: message,
      duration: ok ? null : duration,
      position: 'bottom',
      buttons: [
        {
          side: 'start',
          icon: 'star',
          text: 'Favorite',
          handler: () => {
            console.log('Favorite clicked');
          }
        },
        {
          text: 'Ok',
          role: 'cancel',
          handler: () => {
            console.log('Cancel clicked');
          }
        }
      ]
    });

    toast.present();
  }
}
