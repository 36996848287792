import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseResourceService } from '@shared/services/firebase-resource.service';
import { IPlatformSetup, PlatformSetupSerializer } from '@app/shared-models/commonmodel';

@Injectable()
export class PlatformSetupDbService extends FirebaseResourceService<IPlatformSetup> {
  defaultPath = 'platform-setup';
  constructor(afs: AngularFirestore) {
    super(afs, new PlatformSetupSerializer());
  }
}
