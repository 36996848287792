import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { ICountrySettings, ICountry, ILanguage, ICurrency, IOption } from '@koomzo/coremodel';
import * as utils from '@koomzo/commonutil';
import { IAppStateModel } from '../store/app.state';

@Injectable()
export class InternalizationService {
  protected countryCode: string;
  get current(): ICountrySettings {
    return null;
  }
  protected countrySettingMap: Map<string, ICountrySettings>;
  constructor(private _store: Store) {
    this.countrySettingMap = new Map<string, ICountrySettings>();
  }

  getCountryList(): IOption[] {
    const result: IOption[] = [];
    // const dataD1 = this._store.selectSnapshot<ICountry[]>((state: IAppStateModel) => state.countryList);
    const dataD = this._store.selectSnapshot(state => state.app.countryList);
    if (!utils.isNullOrUndefined(dataD)) {
      dataD.forEach(t => {
        result.push({ code: `${t.code}_${t.callingCode}`, name: t.name, isSelected: false });
      });
    }
    return result;
  }

  getLanguageList(): IOption[] {
    const result: IOption[] = [];
    const dataD = this._store.selectSnapshot(state => state.app.languageList);
    if (!utils.isNullOrUndefined(dataD)) {
      dataD.forEach(t => {
        result.push({ code: t.code, name: t.name, isSelected: false });
      });
    }
    return result;
  }

  getCurrencyList(): IOption[] {
    const result: IOption[] = [];
    const dataD = this._store.selectSnapshot(state => state.app.currencyList);
    if (!utils.isNullOrUndefined(dataD)) {
      dataD.forEach(t => {
        result.push({ code: t.code, name: t.name, isSelected: false });
      });
    }
    return result;
  }

  getLocalizationSettings(countryCode: string): Promise<ICountrySettings> {
    const dataD = this._store.selectSnapshot(state => state.app.localizationSettingsList);
    let result = dataD.find(d => d.countryCode === countryCode);
    return Promise.resolve<ICountrySettings>(result);
  }
}
