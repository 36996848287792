import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';

import * as firebase from 'firebase';
import { Upload } from '../models/upload';
import { Injectable } from '@angular/core';

/**
 * using raw firebase to upload files(images/videos)
 */

@Injectable()
export class FirebaseStorageService {
  constructor() {}

  private basePath = '/uploads';
  /**
   * @param  {Upload} upload
   * @param  {(file:Upload)=>{}} cb?
   */
  pushUpload(upload: Upload, cb?: (file: Upload) => void) {
    const storageRef: firebase.storage.Reference = firebase.storage().ref();
    const uploadTask: firebase.storage.UploadTask = storageRef
      .child(`${this.basePath}/${upload.file.name}`)
      .put(upload.file);

    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED,
      (snapshot: firebase.storage.UploadTaskSnapshot) => {
        // upload in progress
        upload.progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      },
      error => {
        // upload failed
        console.log(error);
      },
      () => {
        // upload success
        //upload.url = uploadTask.snapshot.downloadURL;
        upload.name = upload.file.name;

        uploadTask.snapshot.ref.getDownloadURL().then(function(downloadURL) {
          console.log('File available at', downloadURL);
          upload.url = downloadURL;
          upload.$key = uploadTask.snapshot.metadata.fullPath;
          //save file url to corresponding domain object in firestore
          if (cb) {
            cb(upload);
          }
        });
      }
    );
  }

  // Firebase files must have unique names in their respective storage dir
  // So the name serves as a unique key
  private deleteFileStorage(name: string) {
    const storageRef = firebase.storage().ref();
    storageRef.child(`${this.basePath}/${name}`).delete();
  }
}
