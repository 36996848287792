import { IResource, IAuditData, ISerializer, IGeoLocation, IEventSchedule } from '@koomzo/coremodel';
import * as util from '@koomzo/commonutil';

/**
 * @description notification message with optional payload and predefined set of user-visible keys
 */
export interface IPushNotificationMessage extends IAuditData, IResource {
  tenantId?: string;
  type: string; //push|local
  notification: INotificationDisplayMessage;
  data: INotificationDataMessage[];

  /**This parameter specifies the recipient of a message.
   *  The value can be a device's registration token, a device group's notification key,
   *  or a single topic (prefixed with /topics/).
   * To send to multiple topics, use the condition parameter.*/
  to?: string;
  collaps_key?: string;
  // additional properties

  // determine whether push is topic or device based
  isSendTargetTopic: boolean;
  topics?: string[];
  isTopic?: boolean;

  // for device as push notiifcation target, we need the device group name: all, android, ios
  deviceGroup?: string;
  //sendTargetIsTopic: boolean;
  deviceList?: string[]; // list of device tokens
  schedule?: IEventSchedule;
  //notication point of interest; users within a certain configured radius off this poi will receive a notification
  poi?: IGeoLocation;
  mapMarker?: IMapMarker;
}

export class PushNotificationMessage implements IPushNotificationMessage {
  id: string;
  tenantId?: string;
  type: string;
  notification: INotificationDisplayMessage;
  data: INotificationDataMessage[];

  to?: string;
  collaps_key?: string;
  isSendTargetTopic: boolean;
  isTopic?: boolean;
  topics?: string[];
  deviceGroup?: string;
  deviceList?: string[];
  schedule?: IEventSchedule;
  poi?: IGeoLocation;
  mapMarker?: IMapMarker;
  created: Date;
  updated: Date;
  constructor(value: {
    id: string;
    tenantId?: string;
    type: string;
    notification: INotificationDisplayMessage;
    data: INotificationDataMessage[];
    collaps_key?: string;
    deviceGroup?: string;
    isTopic?: boolean;
    topics?: string[];
    deviceList?: string[];
    schedule?: IEventSchedule;
    poi?: IGeoLocation;
    mapMarker?: IMapMarker;
  }) {
    this.id = value.id;
    this.tenantId = value.tenantId;
    this.type = value.type;
    this.notification = value.notification;
    this.data = value.data;
    this.isTopic = value.isTopic;
    this.topics = value.topics;
    this.deviceGroup = value.deviceGroup;
    this.deviceList = value.deviceList;
    this.schedule = value.schedule;
    this.poi = value.poi;
    this.mapMarker = value.mapMarker;
    this.collaps_key = value.collaps_key;
  }

  execute? = (): void => {
    if (!this.id) {
      this.created = new Date();
      this.updated = new Date();
    }

    if (this.id) {
      this.updated = new Date();
    }

    util.nullifyUndefinedlProps(this);
    return;
  };
}

/** @description plain notification message definition with no payload
 * Notification messages, sometimes thought of as "display messages." These are handled by the FCM SDK automatically.
 * FCM automatically displays the message to end-user devices on behalf of the client app.
 * Notification messages have a predefined set of user-visible keys and an optional data payload of custom key-value pairs.
 */
export interface INotificationDisplayMessage {
  title: string;
  body: string;
}

/**
 * Data messages, which are handled by the client app.
 * Client app is responsible for processing data messages. Data messages have only custom key-value pairs.
 */
export interface INotificationDataMessage {
  key: string;
  value: string;
  uiKey?: string;
}

export class PushNotificationSerializer implements ISerializer {
  fromJson(json: any): IPushNotificationMessage {
    const model = new PushNotificationMessage({ ...json });
    return model;
  }

  toJson(jmodel: PushNotificationMessage): any {
    const model = new PushNotificationMessage({ ...jmodel });
    if (model.execute) {
      model.execute();
    }
    return { ...model };
  }

  // toJson(model: PushNotificationMessage): any {
  //   return {
  //     id: model.id,
  //     tenantId: model.tenantId,
  //     type: model.type,
  //     notification: model.notification,
  //     data: model.data,
  //     isSendTargetTopic: model.isSendTargetTopic,
  //     deviceGroup: model.deviceGroup,
  //     //sendTargetIsTopic: model.sendTargetIsTopic,
  //     topics: model.topics,
  //     deviceList: model.deviceList,
  //     // to: model.to,
  //     collaps_key: model.collaps_key,
  //     created: model.created,
  //     updated: model.updated
  //   };
  // }
}

export interface IMapMarker {
  labelContent: string;
  colorNameValuePair: string;
  iconUrl: string;
}

export interface IPushNotificationSentLog extends IResource {
  tenantId?: string;
  pushNotifcationMessageId: string;
  title: string;
  sentBy: string; //username
  sendCount?: number;
  sentDate: Date;
}

export class PushNotificationSentLog implements IPushNotificationSentLog {
  tenantId?: string;
  pushNotifcationMessageId: string;
  title: string;
  sentBy: string;
  sendCount?: number;
  sentDate: Date;
  created?: Date;
  updated?: Date;
  id?: string;

  constructor(value: { id?: string; tenantId?: string; pushNotifcationMessageId: string; title: string; sentBy: string }) {
    this.id = value.id;
    this.tenantId = value.tenantId;
    this.pushNotifcationMessageId = value.pushNotifcationMessageId;
    this.title = value.title;
    this.sentBy = value.sentBy;
    this.sentDate = new Date();
  }
}

export class PushNotificationSentLogSerializer implements ISerializer {
  fromJson(json: any): IPushNotificationSentLog {
    const model = new PushNotificationSentLog({ ...json });
    return model;
  }

  toJson(model: PushNotificationSentLog): any {
    const jsModel = { ...model };
    return jsModel;
  }
}

export interface IUserCampaignActivity {
  campaignTitle: string;
  campaignAuthor: string;
  userLocation?: IGeoLocation;
  credits?: number;
  creditExpiryDate?: number;
  //has the credits for this campaign been converted?
  isUsed: boolean;
}

export class UserCampaignActivity implements IUserCampaignActivity {
  campaignTitle: string;
  campaignAuthor: string;
  userLocation?: IGeoLocation;
  credits?: number;
  creditExpiryDate?: number;
  isUsed: boolean;

  constructor(value: {
    campaignTitle: string;
    campaignAuthor: string;
    userLocation?: IGeoLocation;
    credits?: number;
    creditExpiryDate?: number;
  }) {
    this.campaignTitle = value.campaignTitle;
    this.campaignAuthor = value.campaignAuthor;
    this.userLocation = value.userLocation;
    this.credits = value.credits;
    this.creditExpiryDate = value.creditExpiryDate;
  }
}

export interface IUserCampaignRecord extends IAuditData, IResource {
  campaignActivity: IUserCampaignActivity[];
  email?: string;
  mobile: string;
  execute?: () => void;
  //business logic
  getEarnedCredits: () => number;
  setTotalEarnedCredits: () => void;
  useCredit?: () => void;
}

export class UserCampaignRecord implements IUserCampaignRecord {
  campaignActivity: IUserCampaignActivity[];
  earnedCredits?: number;
  displayOrder?: number;
  selected?: boolean;
  email?: string;
  mobile: string;
  created?: Date;
  updated?: Date;
  updatedByUid?: string;
  updateByEmail?: string;
  updatedByMobile?: string;
  id?: string;
  execute?: () => void;

  constructor(value: { id?: string; email?: string; mobile: string }) {
    this.id = value.id;
    this.email = value.email;
    this.mobile = value.mobile;

    if (util.isNullOrUndefined(this.campaignActivity)) {
      this.campaignActivity = [];
    }

    this.execute = (): void => {
      if (!this.id) {
        this.created = new Date();
        this.updated = new Date();
      }

      if (this.id) {
        this.updated = new Date();
      }
      util.nullifyUndefinedlProps(this);
      return;
    };
  }
  AddNewUserCampiagn(value: {
    campaignTitle: string;
    campaignAuthor: string;
    userLocation?: IGeoLocation;
    credits?: number;
    creditExpiryDate?: number;
  }) {
    const campaign = new UserCampaignActivity({ ...value });
    this.campaignActivity.push(campaign);
  }

  getEarnedCredits(): number {
    return -1;
  }

  setTotalEarnedCredits(): void {
    return;
  }

  useCredit(): void {
    return;
  }
}
